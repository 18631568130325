import {
  GET_NUMBERIDDONEBYEXPERT_FAILED,
  GET_NUMBERIDDONEBYEXPERT_SUCCEED,
  GET_NUMBERIDDONEBYEXPERT_PROCESSING,
  GET_RETAILERGROUPSREPORT_PROCESSING,
  GET_RETAILERGROUPSREPORT_SUCCEED,
  GET_RETAILERGROUPSREPORT_FAILED,
  GET_CHATSBYEXPERT_PROCESSING,
  GET_CHATSBYEXPERT_SUCCEED,
  GET_CHATSBYEXPERT_FAILED,
  HIDE_ALERT
} from '../constants'

const initialState = {
  fetching: false,
  fetched: false,
  error: false,
  errorMessage: '',
  numberIdDoneByExpert: null,
  retailerGroupsReport: {
    fetching: false,
    fetched: false,
    productImpression: {
      labels: [],
      datasets: []
    },
    productClick: {
      labels: [],
      datasets: []
    }
  },
  chatsByExpert: {
    fetching: false,
    fetched: false,
    data: {}
  }
}

const report = (state = initialState, action) => {
  switch (action.type) {
    case GET_NUMBERIDDONEBYEXPERT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error
      }
    case GET_NUMBERIDDONEBYEXPERT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false
      }
    case GET_NUMBERIDDONEBYEXPERT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        numberIdDoneByExpert: action.payload.numberIdDoneByExpert
      }
    case HIDE_ALERT:
      return {
        ...state,
        error: false,
        errorMessage: ''
      }
    case GET_RETAILERGROUPSREPORT_PROCESSING:
      return {
        ...state,
        retailerGroupsReport: {
          ...state.retailerGroupsReport,
          fetching: true,
          fetched: false
        }
      }
    case GET_RETAILERGROUPSREPORT_SUCCEED:
      return {
        ...state,
        retailerGroupsReport: {
          ...state.retailerGroupsReport,
          data: action.payload.retailerGroupsReport,
          fetching: false,
          fetched: true
        }
      }
    case GET_RETAILERGROUPSREPORT_FAILED:
      return {
        ...state,
        retailerGroupsReport: {
          ...state.retailerGroupsReport,
          fetching: false,
          fetched: false
        },
        error: true,
        errorMessage: action.payload.message
      }
    case GET_CHATSBYEXPERT_PROCESSING:
      return {
        ...state,
        chatsByExpert: {
          ...state.chatsByExpert,
          fetching: true,
          fetched: false
        }
      }
    case GET_CHATSBYEXPERT_SUCCEED:
      return {
        ...state,
        chatsByExpert: {
          ...state.chatsByExpert,
          data: action.payload.chatsByExpert,
          fetching: false,
          fetched: true
        }
      }
    case GET_CHATSBYEXPERT_FAILED:
      return {
        ...state,
        chatsByExpert: {
          ...state.chatsByExpert,
          fetching: true,
          fetched: false
        },
        error: true,
        errorMessage: action.payload.message
      }
    default:
      return state
  }
}

export default report
