import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import Image from 'react-image'

class CustomImageInput extends Component {
  state = {
    uploading: false,
  }
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    label: PropTypes.string,
  }

  fileInputRef = React.createRef()

  setFile = async () => {
    this.uploading = true
    let fileToUpload = this.fileInputRef.current.files[0]
    if (fileToUpload) {
      var reader = new FileReader()
      reader.readAsDataURL(fileToUpload)
      reader.onloadend = () => {
        this.uploading = false
        var base64data = reader.result
        this.props.input.onChange({
          changed: true,
          uri: URL.createObjectURL(fileToUpload),
          imageData: base64data,
        })
      }
    } else {
      this.uploading = false
    }
  }

  render() {
    const { input, label } = this.props
    return (
      <>
        <input
          onChange={this.setFile}
          type="file"
          ref={this.fileInputRef}
          style={{ display: 'none' }}
        />
        <Grid container direction="column">
          <Grid item>{this.uploading && <CircularProgress />}</Grid>
          <Grid item>
            {input.value && (
              <Image
                src={input.value.uri}
                alt={'image'}
                style={{ maxWidth: 128 }}
              />
            )}
          </Grid>
          <Grid item>
            <Button
              onClick={() => this.fileInputRef.current.click()}
              variant="contained"
              color="secondary"
            >
              {label}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default CustomImageInput
