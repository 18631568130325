export const COUNT_PRODUCT_PROCESSING = 'COUNT_PRODUCT_PROCESSING'
export const COUNT_PRODUCT_SUCCEED = 'COUNT_PRODUCT_SUCCEED'
export const COUNT_PRODUCT_FAILED = 'COUNT_PRODUCT_FAILED'
export const FETCH_PRODUCT_PROCESSING = 'FETCH_PRODUCT_PROCESSING'
export const FETCH_PRODUCT_SUCCEED = 'FETCH_PRODUCT_SUCCEED'
export const FETCH_PRODUCT_FAILED = 'FETCH_PRODUCT_FAILED'
export const GET_PRODUCT_PROCESSING = 'GET_PRODUCT_PROCESSING'
export const GET_PRODUCT_SUCCEED = 'GET_PRODUCT_SUCCEED'
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED'
export const FETCH_PRODUCT_REGIONS_PROCESSING = 'FETCH_PRODUCT_REGIONS_PROCESSING'
export const FETCH_PRODUCT_REGIONS_SUCCEED = 'FETCH_PRODUCT_REGIONS_SUCCEED'
export const FETCH_PRODUCT_REGIONS_FAILED = 'FETCH_PRODUCT_REGIONS_FAILED'
export const SAVE_PRODUCT_PROCESSING = 'SAVE_PRODUCT_PROCESSING'
export const SAVE_PRODUCT_SUCCEED = 'SAVE_PRODUCT_SUCCEED'
export const SAVE_PRODUCT_FAILED = 'SAVE_PRODUCT_FAILED'
export const DELETE_PRODUCT_PROCESSING = 'DELETE_PRODUCT_PROCESSING'
export const DELETE_PRODUCT_SUCCEED = 'DELETE_PRODUCT_SUCCEED'
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED'
export const HIDE_ALERT = 'HIDE_ALERT'
