import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { CardActions, Button, TextField } from '@material-ui/core'

const validate = values => {
  const errors = {}
  const requiredFields = ['email', 'password']
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }
  return errors
}

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    helperText={touched ? error : ''}
    error={touched ? error !== undefined : false}
    {...inputProps}
    {...props}
    fullWidth
  />
)

let LoginForm = props => {
  const styles = {
    form: {
      padding: '0 1em 1em 1em'
    },
    input: {
      display: 'flex'
    }
  }

  const { handleSubmit } = props
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={styles.form}>
          <div style={styles.input}>
            <Field
              id="email"
              name="email"
              component={renderInput}
              label="Email"
            />
          </div>
          <div style={styles.input}>
            <Field
              id="password"
              name="password"
              component={renderInput}
              label="Password"
              type="password"
            />
          </div>
        </div>
        <CardActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            label="Sign in"
            fullWidth={true}
          >
            Login
          </Button>
        </CardActions>
      </form>
    </div>
  )
}

LoginForm = reduxForm({
  form: 'login',
  validate
})(LoginForm)
export default LoginForm
