import {
  expert as expertAPI
} from '../api'
import {
  HIDE_ALERT
} from '../constants'
export const expert = {
  list(page, rowsPerPage, query) {
    return (dispatch) => {
      dispatch(expertAPI.list(page, rowsPerPage, query))
    }
  },
  get(id) {
    return (dispatch) => {
      dispatch(expertAPI.get(id))
    }
  },
  getRegions(query) {
    return (dispatch) => {
      dispatch(expertAPI.getRegions(query))
    }
  },
  count(query) {
    return (dispatch) => {
      dispatch(expertAPI.count(query))
    }
  },
  save(values) {
    return (dispatch) => {
      dispatch(expertAPI.save(values))
    }
  },
  delete(id) {
    return (dispatch) => {
      dispatch(expertAPI.delete(id))
    }
  },
  hideAlert() {
    return (dispatch) => {
      dispatch({
        type: HIDE_ALERT
      })
    }
  }
}