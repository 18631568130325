import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { genus as genusActions } from '../../actions'
import CustomTable from '../Table/CustomTable'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button } from '@material-ui/core'
import debounce from 'lodash.debounce'
import CustomSnackbar from 'components/CustomSnackbar'

class GenusList extends Component {
  static propTypes = {
    count: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    delete: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired,
    deleted: PropTypes.bool,
    total: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
  }

  state = {
    page: 0,
    rowsPerPage: 10,
    fields: [
      {
        id: 'name',
        label: 'Genus',
      },
      {
        id: 'categoryType',
        label: 'Category Type',
      },
      {
        id: 'commonName',
        label: 'Latin Name',
      },
    ],
  }
  componentDidMount() {
    this.props.count()
    this._getGenus()
  }
  _getGenus() {
    this.props.fetch(this.state.page, this.state.rowsPerPage)
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getGenus()
    })
  }
  handleSearch = debounce(query => {
    this.props.count(query)
    this.props.fetch(this.state.page, this.state.rowsPerPage, query)
  }, 1000)
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getGenus()
    })
  }
  handleDeleteAction = id => {
    this.props.delete(id)
  }
  hideAlert() {
    this.props.hideAlert()
    window.location.reload()
  }
  render() {
    const { list, total, history, errorMessage } = this.props
    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={Boolean(errorMessage)}
          message={errorMessage}
          handleClose={() => this.handleCloseError()}
        />
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push('/genus/add')}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title="Genus"
          total={total}
          history={history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={list}
          editPath="/genus/edit/"
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: '/genus/edit/',
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  total: state.genus.total,
  list: state.genus.list,
  deleted: state.genus.deleted,
  errorMessage: state.genus.errorMessage,
})

const mapDispatchToProps = { ...genusActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenusList)
