import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles, Menu, Button, MenuItem } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'

import { auth as authActions } from '../../actions'
import headerLinksStyle from '../../variables/styles/headerLinksStyle'
class HeaderLinks extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  }
  signOut = () => {
    this.props.logout()
  }
  handleClick = e => {
  }
  handleClose = () => {
  }
  render() {
    return (
      <>
        <Button onClick={this.handleClick}>
          <MenuIcon />
        </Button>
        <Menu
          id="main-menu"
          anchorEl={this.menu}
          open={Boolean(this.menu)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.signOut}>Sign out</MenuItem>
        </Menu>
      </>
    )
  }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = { ...authActions }

export default withStyles(headerLinksStyle)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderLinks)
)
