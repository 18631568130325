import {
  COUNT_PRODUCT_FAILED,
  COUNT_PRODUCT_SUCCEED,
  COUNT_PRODUCT_PROCESSING,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_SUCCEED,
  FETCH_PRODUCT_PROCESSING,
  GET_PRODUCT_FAILED,
  GET_PRODUCT_SUCCEED,
  GET_PRODUCT_PROCESSING,
  FETCH_PRODUCT_REGIONS_FAILED,
  FETCH_PRODUCT_REGIONS_SUCCEED,
  FETCH_PRODUCT_REGIONS_PROCESSING,
  SAVE_PRODUCT_FAILED,
  SAVE_PRODUCT_SUCCEED,
  SAVE_PRODUCT_PROCESSING,
  DELETE_PRODUCT_FAILED,
  DELETE_PRODUCT_SUCCEED,
  DELETE_PRODUCT_PROCESSING,
  HIDE_ALERT,
} from '../constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorMessage: '',
  list: [],
  total: 0,
  regions: [],
  products: [],
  record: {
    object_id: '',
    productImage: '',
    productName: '',
    productDescription: '',
    productLink: '',
    Regions: [],
  },
}

const product = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
        list: [],
      }
    case FETCH_PRODUCT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case FETCH_PRODUCT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        errorMessage: '',
      }
    case GET_PRODUCT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
        record: initialState.record,
      }
    case GET_PRODUCT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        record: initialState.record,
        errorMessage: '',
      }
    case GET_PRODUCT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        errorMessage: '',
      }
    case COUNT_PRODUCT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_PRODUCT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_PRODUCT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_PRODUCT_REGIONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_PRODUCT_REGIONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_PRODUCT_REGIONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        regions: action.payload.regions,
      }
    case SAVE_PRODUCT_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
        errorMessage: action.payload.error,
      }
    case SAVE_PRODUCT_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_PRODUCT_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_PRODUCT_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_PRODUCT_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_PRODUCT_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        errorMessage: '',
      }
    default:
      return state
  }
}

export default product
