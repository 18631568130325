import {
  COUNT_RETAILER_FAILED,
  COUNT_RETAILER_SUCCEED,
  COUNT_RETAILER_PROCESSING,
  FETCH_RETAILER_FAILED,
  FETCH_RETAILER_SUCCEED,
  FETCH_RETAILER_PROCESSING,
  GET_RETAILER_FAILED,
  GET_RETAILER_SUCCEED,
  GET_RETAILER_PROCESSING,
  FETCH_RETAILER_PRODUCTS_FAILED,
  FETCH_RETAILER_PRODUCTS_SUCCEED,
  FETCH_RETAILER_PRODUCTS_PROCESSING,
  SAVE_RETAILER_FAILED,
  SAVE_RETAILER_SUCCEED,
  SAVE_RETAILER_PROCESSING,
  DELETE_RETAILER_FAILED,
  DELETE_RETAILER_SUCCEED,
  DELETE_RETAILER_PROCESSING,
  HIDE_ALERT,
} from '../constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorMessage: '',
  list: [],
  total: 0,
  retailerProducts: [],
  products: [],
  record: {},
}

const retailer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RETAILER_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
        list: [],
      }
    case FETCH_RETAILER_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case FETCH_RETAILER_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        errorMessage: '',
      }
    case GET_RETAILER_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
        record: initialState.record,
      }
    case GET_RETAILER_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case GET_RETAILER_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        retailerProducts: action.payload.retailerProducts,
        errorMessage: '',
      }
    case COUNT_RETAILER_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_RETAILER_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_RETAILER_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_RETAILER_PRODUCTS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_RETAILER_PRODUCTS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_RETAILER_PRODUCTS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        products: action.payload.products,
      }
    case SAVE_RETAILER_FAILED:
      return {
        ...state,
        saving: false,
        saved: true,
        errorMessage: action.payload.error,
      }
    case SAVE_RETAILER_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_RETAILER_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_RETAILER_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_RETAILER_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_RETAILER_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        errorMessage: '',
        deleted: false,
      }
    default:
      return state
  }
}

export default retailer
