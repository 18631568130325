import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import {
  Button,
  Grid,
  withStyles,
  Fade,
  CircularProgress,
  Container,
} from '@material-ui/core'

import { product as productActions } from '../../actions/product.actions'
import CustomImageUpload from '../Input/CustomImageUpload'
import CustomTextField from '../Input/CustomTextField'
import formPropTypes from '../../constants/formPropTypes'
import CustomMultiSelect from 'components/Input/CustomMultiSelect'
import CustomSnackbar from 'components/CustomSnackbar'

import { overlay, loadingSpinner } from '../../variables/styles'
import { RegularCard } from 'components'

export const fields = [
  'object_id',
  'productImage',
  'productName',
  'productDescription',
  'Regions',
  'productLink',
]

const validate = values => {
  const errors = {}
  const requiredFields = ['productName', 'productDescription']
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    values.object_id = props.initialValues.object_id
    await props.save(values)
  } catch (err) {
    throw new SubmissionError({ _error: err.message })
  }
}

class ProductForm extends Component {
  static propTypes = {
    ...formPropTypes,
    selectedProduct: PropTypes.object,
  }

  async componentDidMount() {
    await this.props.getRegions()

    if (this.props.match.params.id) {
      await this.props.get(this.props.match.params.id)
    }
  }

  assignProduct = async () => {
    if (this.props.record) {
      if (this.props.selectedProduct) {
        await this.props.assignProduct(
          this.props.record.id,
          this.props.selectedProduct.id
        )
      }
    }
  }
  async hideAlert() {
    await this.props.get(this.props.match.params.id)
  }

  handleCloseError = () => {
    this.props.hideAlert()
  }
  render() {
    const {
      record,
      classes,
      regions,
      handleSubmit,
      submitSucceeded,
      error,
      fetching,
    } = this.props

    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') + record.productName

    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={Boolean(error)}
          message={error}
          handleClose={() => this.handleCloseError()}
        />
        <CustomSnackbar
          variant="success"
          open={submitSucceeded}
          message={'Saved'}
          autoHideDuration={1000}
          onClose={() => this.hideAlert()}
        />
        <Fade in={fetching}>
          <div
            className={classes.overlay}
            style={{ zIndex: fetching ? 10 : 0 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <Container>
          <RegularCard
            cardTitle={title}
            content={
              <form onSubmit={handleSubmit}>
                <Grid item>
                  <Field
                    name="productImage"
                    label="Product Image"
                    component={CustomImageUpload}
                  />
                </Grid>
                <Grid item className={classes.field}>
                  <Field
                    name="productName"
                    component={CustomTextField}
                    label="Products Name"
                  />
                </Grid>
                <Grid item className={classes.field}>
                  <Field
                    name="productLink"
                    component={CustomTextField}
                    label="Link"
                  />
                </Grid>
                <Grid item className={classes.field}>
                  <Field
                    multiline={true}
                    name="productDescription"
                    component={CustomTextField}
                    label="Description"
                  />
                </Grid>
                <Grid item className={classes.field}>
                  <Field
                    name="Regions"
                    label="Region"
                    options={regions}
                    placeholder="Select regions"
                    component={CustomMultiSelect}
                  />
                </Grid>
              </form>
            }
            footer={
              <>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={fetching}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.history.push('/product')}
                >
                  Back to list
                </Button>
              </>
            }
          />
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ product }) => ({
  ...product,
  fields,
  initialValues: product.record,
})

const mapDispatchToProps = { ...productActions }

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  headline: {
    color: '#25A652',
  },
  field: {
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  loadingSpinner,
  overlay,
})
export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      // a unique name for the form
      form: 'product',
      validate,
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(ProductForm)
  )
)
