import retailerAPI from '../api/modules/retailer.api'
import {
  HIDE_ALERT
} from '../constants'
export const retailer = {
  fetch(page, rowsPerPage, query) {
    return (dispatch) => {
      dispatch(retailerAPI.list(page, rowsPerPage, query))
    }
  },
  get(id) {
    return (dispatch) => {
      dispatch(retailerAPI.get(id))
    }
  },
  getCategories() {
    return (dispatch) => {
      dispatch(retailerAPI.getCategories())
    }
  },
  getProducts(query) {
    return (dispatch) => {
      dispatch(retailerAPI.getProducts(query))
    }
  },
  count(query) {
    return (dispatch) => {
      dispatch(retailerAPI.count(query))
    }
  },
  save(values) {
    return (dispatch) => {
      dispatch(retailerAPI.save(values))
    }
  },
  delete(id) {
    return (dispatch) => {
      dispatch(retailerAPI.delete(id))
    }
  },
  hideAlert() {
    return (dispatch) => {
      dispatch({
        type: HIDE_ALERT
      })
    }
  }
}
