/*!

 =========================================================
 * Material Dashboard React - v1.0.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 260

const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
}

const card = {}

const primaryColor = '#298147'
const primaryLighterColor = '#25A652'
const secondaryColor = '#55ACEE'
const warningColor = '#F5A623'
const dangerColor = '#FF0054'
const successColor = '#4caf50'
const infoColor = '#55ACEE'
const roseColor = '#e91e63'
const grayColor = '#999999'
const typoHeaderColor = '#2F2E2E !important'
const typoBodyColor = '#6F6F6F'
const typoSecondaryColor = '#9B9B9B'

const orangeCardHeader = {
  background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
}
const greenCardHeader = {
  background: 'linear-gradient(60deg, #66bb6a, #43a047)',
}
const redCardHeader = {
  background: 'linear-gradient(60deg, #ef5350, #e53935)',
}
const blueCardHeader = {
  background: 'linear-gradient(60deg, #26c6da, #00acc1)',
}
const purpleCardHeader = {
  background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
}

const cardActions = {
  margin: '0 20px 10px',
  paddingTop: '10px',
  borderTop: '1px solid #eeeeee',
  height: 'auto',
  
}

const cardHeader = {
  /* margin: '-20px 15px 0',
  borderRadius: '3px',
  padding: '15px', */
}

const loadingSpinner = {
  position: 'fixed',
  zIndex: 10,
  left: '50%',
  top: '50%',
}

const overlay = {
  width: '100%',
  height: '100%',
  background: '#ffffffee',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10,
}

export {
  //variables
  drawerWidth,
  transition,
  card,
  
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  primaryLighterColor,
  secondaryColor,
  typoHeaderColor,
  typoBodyColor,
  typoSecondaryColor,
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  cardActions,
  cardHeader,
  loadingSpinner,
  overlay,
}
