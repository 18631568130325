import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Button, withStyles, Grid } from '@material-ui/core'

import RegularCard from '../Cards/RegularCard.js'
import { barcode as barcodeActions, unmatchedBarcode } from '../../actions'
import CustomTextField from '../Input/CustomTextField.js'
import CustomFileInput from '../Input/CustomFileInput.js'
import CustomSnackbar from 'components/CustomSnackbar/index.js'
import CustomSelect from 'components/Input/CustomSelect.js'

export const fields = ['object_id', 'file', 'retailersGroup']

const validate = values => {
  const errors = {}
  if (!values.retailersGroup || !values.retailersGroup.value) {
    errors.retailersGroup = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.file) {
    errors.file = 'Required'
  }
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  try {
    await props.import(values)
  } catch (err) {
    throw new SubmissionError({ _error: 'Import failed' })
  }
}

class BarcodeImport extends Component {
  static propTypes = {
    retailersGroups: PropTypes.array.isRequired,
    import: PropTypes.func.isRequired,
    importing: PropTypes.bool.isRequired,
    imported: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    fetching: PropTypes.bool,
    unmatchedBarcodesTotal: PropTypes.number.isRequired,
    getRetailersGroups: PropTypes.func.isRequired,
    countUnmatchedBarcodes: PropTypes.func.isRequired,
  }

  file = null

  async componentDidMount() {
    await this.props.countUnmatchedBarcodes()
    await this.props.getRetailersGroups()
  }

  didSelectFile = e => {
    const files = e.target.files
    if (files.length === 0) return
    const file = files[0]
    this.file = file
  }

  hideAlert() {
    this.props.reset()
  }

  render() {
    const {
      handleSubmit,
      invalid,
      fetching,
      submitting,
      errorMessage,
      submitSucceeded,
      submitFailed,
      retailersGroups,
    } = this.props
    const title = 'Import'
    return (
      <>
        <CustomSnackbar
          variant="error"
          open={Boolean(submitFailed)}
          message={errorMessage}
          handleClose={() => this.handleCloseError()}
        />
        <CustomSnackbar
          variant="success"
          open={Boolean(submitSucceeded)}
          message={'Saved'}
          autoHideDuration={1000}
          onClose={() => this.hideAlert()}
        />
        <form onSubmit={handleSubmit}>
          <RegularCard
            cardTitle={title}
            content={
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <label>Retailers Group</label>
                  <Field
                    name="retailersGroup"
                    label="Retailers Group"
                    placeholder="Select a retailers group"
                    options={retailersGroups}
                    component={CustomSelect}
                  />
                </Grid>

                <Grid item>
                  <p>Upload file with barcodes</p>
                  <Field
                    name="file"
                    label="Select file"
                    accept=".csv"
                    component={CustomFileInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    name="email"
                    component={CustomTextField}
                    label="Email"
                  />
                </Grid>
              </Grid>
            }
            footer={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={fetching || invalid || submitting}
                  >
                    Import
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.props.history.push('/barcode')}
                  >
                    Back to list
                  </Button>
                </Grid>
              </Grid>
            }
          />
        </form>
      </>
    )
  }
}

const mapStateToProps = ({ barcode, unmatchedBarcode }) => ({
  retailersGroups: barcode.retailersGroups,
  importing: barcode.importing,
  imported: barcode.imported,
  errorMessage: barcode.errorMessage,
  unmatchedBarcodesTotal: unmatchedBarcode.total,
  fields,
  initialValues: {
    retailersGroup: {},
    file: null,
    email: '',
  },
})

const mapDispatchToProps = {
  ...barcodeActions,
  countUnmatchedBarcodes: unmatchedBarcode.count,
}

const style = theme => ({})

export default withStyles(style)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'barcode-import',
      onSubmit,
      validate,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(BarcodeImport)
  )
)
