import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Button, CardActions, Grid } from '@material-ui/core'

import RegularCard from '../Cards/RegularCard.js'
import { genus as genusActions } from '../../actions'
import CustomTextField from 'components/Input/CustomTextField.js'
import formPropTypes from '../../constants/formPropTypes'
import CustomMultiSelect from 'components/Input/CustomMultiSelect.js'
import CustomSelect from 'components/Input/CustomSelect.js'
import CustomSnackbar from 'components/CustomSnackbar/index.js'

export const fields = [
  'object_id',
  'genusName',
  'categoryType',
  'commonName',
  'genusProduct',
]

const validate = values => {
  const errors = {}
  const requiredFields = ['genusName', 'commonName']
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  values.object_id = props.initialValues.object_id
  try {
    await props.save(values)
  } catch (err) {
    throw new SubmissionError({ _error: err.message })
  }
}

class GenusForm extends Component {
  static propTypes = {
    ...formPropTypes,
  }

  componentDidMount() {
    this.props.getCategories()
    this.props.getProducts()
    this.props.get(this.props.match.params.id)
  }
  handleCloseError = () => {
    this.props.hideAlert()
  }
  render() {
    const {
      record,
      categories,
      products,
      handleSubmit,
      submitting,
      submitSucceeded,
      submitFailed,
      error,
    } = this.props
    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') + record.genusName
    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={submitFailed}
          message={error}
          handleClose={() => this.handleCloseError()}
        />
        <CustomSnackbar variant="info" open={submitting} message="Saving" />
        <CustomSnackbar
          variant="success"
          open={submitSucceeded}
          message="Saved"
          onClose={() => {}}
        />
        <RegularCard
          cardTitle={title}
          content={
            <form onSubmit={handleSubmit}>
              <Grid item>
                <Field
                  name="genusName"
                  component={CustomTextField}
                  label="Genus Name"
                />
              </Grid>
              <Grid item>
                <Field
                  name="categoryType"
                  label="Category Type"
                  options={categories}
                  placeholder="Select a category"
                  component={CustomSelect}
                />
              </Grid>
              <Grid item>
                <Field
                  name="commonName"
                  component={CustomTextField}
                  label="Latin Name"
                />
              </Grid>
              <Grid item>
                <Field
                  name="genusProduct"
                  label="Products"
                  options={products}
                  placeholder="Select products"
                  component={CustomMultiSelect}
                />
              </Grid>

              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.props.fetching}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.history.push('/genus')}
                >
                  Back to list
                </Button>
              </CardActions>
            </form>
          }
          footer={''}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ genus }) => ({
  record: genus.record,
  categories: genus.categories,
  products: genus.products,
  fetching: genus.fetching,
  fetched: genus.fetched,
  saving: genus.saving,
  saved: genus.saved,
  fields,
  initialValues: genus.record,
})

const mapDispatchToProps = { ...genusActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'genus',
    validate,
    onSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(GenusForm)
)
