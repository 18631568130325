import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { Button } from '@material-ui/core'
import SweetAlert from 'sweetalert2-react'
import debounce from 'lodash.debounce'

import { region as regionActions } from '../../actions'
import CustomTable from '../Table/CustomTable'

class RegionList extends Component {

  static propTypes = {
    count: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    deleted: PropTypes.bool,
    history: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
  }
  state = {
    page: 0,
    rowsPerPage: 10,
    fields: [
      {
        id: 'regionName',
        label: 'Region Name',
      },
      {
        id: 'hemisphere',
        label: 'Hemisphere',
      },
      {
        id: 'subscriptionCurrency',
        label: 'Subscription Currency',
      },
    ],
  }

  componentDidMount() {
    this.props.count()
    this._getRegion()
  }
  _getRegion() {
    this.props.fetch(this.state.page, this.state.rowsPerPage)
  }
  handleDeleteAction = id => {
    this.props.delete(id)
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getRegion()
    })
  }
  handleSearch = debounce(query => {
    this.props.count(query)
    this.props.fetch(this.state.page, this.state.rowsPerPage, query)
  }, 1000)
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getRegion()
    })
  }
  hideAlert() {
    this.props.hideAlert()
    this.props.record
      ? window.location.reload()
      : this.props.history.push('/regions')
  }
  render() {
    const { list, errorMessage, deleted, total, history } = this.props
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push('/regions/add')}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title={'Region'}
          total={total}
          history={history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={list}
          editPath="/regions/edit/"
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: '/regions/edit/',
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
        <SweetAlert
          type={errorMessage ? 'error' : 'success'}
          show={deleted || Boolean(errorMessage)}
          title={errorMessage ? 'Error' : 'Notice'}
          text={errorMessage ? errorMessage : 'Deleted'}
          onConfirm={this.hideAlert.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  total: state.region.total,
  list: state.region.list,
  deleted: state.region.deleted,
  errorMessage: state.region.errorMessage,
})

const mapDispatchToProps = { ...regionActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionList)
