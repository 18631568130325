import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { Button } from '@material-ui/core'
import debounce from 'lodash.debounce'

import { retailer as retailerActions } from '../../actions'
import CustomTable from '../Table/CustomTable'
import CustomSnackbar from 'components/CustomSnackbar'

class RetailerList extends Component {
  static propTypes = {}

  state = {
    page: 0,
    rowsPerPage: 10,
    fields: [
      { id: 'name', label: 'Name' },
      { id: 'phone', label: 'Phone' },
      { id: 'email', label: 'Email' },
      { id: 'city', label: 'City' },
      { id: 'state', label: 'State' },
      {
        id: 'country',
        label: 'Country',
      },
    ],
  }
  async componentDidMount() {
    await this.props.count()
    await this._getRetailer()
  }
  async _getRetailer() {
    await this.props.fetch(this.state.page, this.state.rowsPerPage)
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getRetailer()
    })
  }
  handleSearch = debounce(query => {
    this.props.count(query)
    this.props.list(this.state.page, this.state.rowsPerPage, query)
  }, 1000)
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getRetailer()
    })
  }
  handleDeleteAction = id => {
    this.props.delete(id)
  }
  hideAlert() {
    this.props.hideAlert()
  }
  render() {
    const { errorMessage } = this.props
    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={Boolean(errorMessage)}
          message={errorMessage && errorMessage.message}
          handleClose={() => this.handleCloseError()}
        />
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push('/retailers/add')}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title="Retailer"
          total={this.props.total}
          history={this.props.history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={this.props.list}
          editPath="/retailers/edit/"
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: '/retailers/edit/',
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ retailer }) => ({
  total: retailer.total,
  list: retailer.list,
  deleted: retailer.deleted,
  errorMessage: retailer.errorMessage,
})

const mapDispatchToProps = { ...retailerActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailerList)
