import React, { Component } from 'react'
import { connect } from 'react-redux'
import userActions from '../../actions/user.actions'
import CustomTable from '../Table/CustomTable'
import { Add as AddIcon, Edit, Delete } from '@material-ui/icons'
import { Button, Fade, CircularProgress, withStyles } from '@material-ui/core'
import debounce from 'lodash.debounce'
import { overlay, loadingSpinner } from '../../variables/styles'

class UserList extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    fields: [
      { id: 'email', label: 'Email' },
      {
        id: 'paidCredits',
        label: 'Paid Credits',
      },
      {
        id: 'regionName',
        label: 'Region',
      },
      {
        id: 'subscriptionStatus',
        label: 'Subscription Status',
      },
      {
        id: 'subscriptionCredits',
        label: 'Subscription Credits',
      },
    ],
  }

  componentDidMount() {
    this.props.count()
    this._getUser()
  }
  _getUser() {
    this.props.fetch(this.state.page, this.state.rowsPerPage)
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getUser()
    })
  }
  handleSearch = debounce(query => {
    this.props.count(query)
    this.props.fetch(this.state.page, this.state.rowsPerPage, query)
  }, 1000)
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getUser()
    })
  }
  handleDeleteAction = id => {
    this.props.delete(id)
  }
  hideAlert(event) {
    this.props.hideAlert()
  }
  render() {
    const { classes } = this.props
    return (
      <div>
        <Fade in={this.props.fetching}>
          <div
            className={classes.overlay}
            style={{ zIndex: this.props.fetching ? 10 : 0 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push('/user/add')}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title="User"
          total={this.props.total}
          history={this.props.history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={this.props.list}
          editPath="/user/edit/"
          actions={[
            {
              label: 'edit',
              icon: <Edit />,
              path: '/user/edit/',
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <Delete />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  total: state.user.total,
  list: state.user.list,
  deleted: state.user.deleted,
  errorMessage: state.user.errorMessage,
})

const mapDispatchToProps = {
  ...userActions,
}

const styles = () => ({
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(UserList)
)
