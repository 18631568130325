import {
  GET_NUMBERIDDONEBYEXPERT_FAILED,
  GET_NUMBERIDDONEBYEXPERT_SUCCEED,
  GET_NUMBERIDDONEBYEXPERT_PROCESSING,
  GET_RETAILERGROUPSREPORT_PROCESSING,
  GET_RETAILERGROUPSREPORT_SUCCEED,
  GET_RETAILERGROUPSREPORT_FAILED,
  GET_CHATSBYEXPERT_PROCESSING,
  GET_CHATSBYEXPERT_SUCCEED,
  GET_CHATSBYEXPERT_FAILED,
} from '../../constants'
import Parse from '../parse'
export default {
  numberIdDoneByExpert(onlyLastMonth) {
    return async dispatch => {
      dispatch({
        type: GET_NUMBERIDDONEBYEXPERT_PROCESSING,
      })
      try {
        const result = await Parse.Cloud.run('numberIdDoneByExpert', {
          totalValue: !onlyLastMonth,
        })
        dispatch({
          type: GET_NUMBERIDDONEBYEXPERT_SUCCEED,
          payload: {
            numberIdDoneByExpert: result,
          },
        })
      } catch (error) {
        dispatch({
          type: GET_NUMBERIDDONEBYEXPERT_FAILED,
          payload: { error },
        })
      }
    }
  },
  getRetailerGroupsReport({
    retailerGroupId,
    fromDate,
    toDate,
    unit,
    outputFormat,
  }) {
    return async dispatch => {
      dispatch({
        type: GET_RETAILERGROUPSREPORT_PROCESSING,
      })
      try {
        const result = await Parse.Cloud.run('generateRetailerGroupsReport', {
          retailerGroupId,
          fromDate,
          toDate,
          unit,
          outputFormat,
        })
        dispatch({
          type: GET_RETAILERGROUPSREPORT_SUCCEED,
          payload: {
            retailerGroupsReport: result,
          },
        })
      } catch (error) {
        dispatch({
          type: GET_RETAILERGROUPSREPORT_FAILED,
          payload: {
            message: error.message,
          },
        })
      }
    }
  },
  getChatsByExpert({ fromDate, toDate }) {
    return async dispatch => {
      dispatch({
        type: GET_CHATSBYEXPERT_PROCESSING,
      })
      try {
        const result = await Parse.Cloud.run('numberChatsByExpert', {
          fromDate,
          toDate,
        })
        dispatch({
          type: GET_CHATSBYEXPERT_SUCCEED,
          payload: {
            chatsByExpert: result,
          },
        })
      } catch (error) {
        dispatch({
          type: GET_CHATSBYEXPERT_FAILED,
          payload: {
            message: error.message,
          },
        })
      }
    }
  },
}
