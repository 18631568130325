import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm, propTypes as reduxFormPropTypes } from 'redux-form'
import { connect } from 'react-redux'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardContent,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardHeader,
  Fade,
  CircularProgress,
  withStyles,
} from '@material-ui/core'
import Select from 'react-select'
import clone from 'lodash.clone'
import { overlay, loadingSpinner } from '../../variables/styles'

import { retailersGroup as retailersGroupActions } from '../../actions'

const onSubmit = (values, dispatch, props) => {
  props.saveProducts(values)
}

class RetailersGroupProductForm extends Component {
  state = {
    deleteAllProductsModalOpen: false,
  }
  static propTypes = {
    ...reduxFormPropTypes,
    initialize: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    savingProducts: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
  }

  componentDidMount() {}

  handleCloseDeleteAllProductsModal = () => {
    this.setState({
      deleteAllProductsModalOpen: false,
    })
  }

  handleDeleteAllProducts = () => {
    this.props.categories.forEach(category => {
      for (let i = 0; i < 3; i++) {
        const fieldName = `cate-${category.value}-product${i + 1}`
        this.props.change(fieldName, {
          value: '',
          label: '',
          category: category.value,
          record: this.props.record.object_id,
        })
      }
    })
    this.setState({
      deleteAllProductsModalOpen: false,
    })
  }

  copyProductToAllCategories = (product, productNumber) => {
    const clonedProduct = clone(product)
    this.props.categories.forEach(category => {
      const fieldName = `cate-${category.value}-product${productNumber}`
      this.props.change(fieldName, clonedProduct)
    })
  }

  render() {
    const {
      record,
      handleSubmit,
      fetching,
      classes,
      categories,
      products,
    } = this.props
    return (
      <Card className={classes.paper}>
        <Fade in={fetching}>
          <div
            className={classes.overlay}
            style={{ zIndex: fetching ? 10 : 0 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <form onSubmit={handleSubmit}>
          <CardHeader
            action={
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.saveProducts}
                  onClick={() =>
                    this.setState({ deleteAllProductsModalOpen: true })
                  }
                  style={{ marginRight: '10px' }}
                >
                  Clear all products
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.saveProducts}
                  type="submit"
                >
                  Save products
                </Button>
              </>
            }
            title="Products"
          />
          <CardContent>
            <Divider style={{ marginBottom: '20px' }} />
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Name</TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: 250 }}
                  >
                    Product 1
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: 250 }}
                  >
                    Product 2
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: 250 }}
                  >
                    Product 3
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map(category => {
                  let listProducts = products.map(p => {
                    return {
                      value: p.value,
                      label: p.label,
                      category: category.value,
                      record: record.id,
                    }
                  })
                  return (
                    <TableRow key={category.value}>
                      <TableCell className={classes.tableCell}>
                        {category.label}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <Field
                          name={`cate-${category.value}-product1`}
                          label="Product 1"
                          component={props => (
                            <>
                              <Select
                                value={props.input.value}
                                onChange={value =>
                                  props.input.onChange({
                                    ...value,
                                    category: category.value,
                                    record: record.object_id,
                                  })
                                }
                                options={listProducts}
                                placeholder="Select product"
                              />
                              <Button
                                size="small"
                                color="secondary"
                                onClick={() =>
                                  this.copyProductToAllCategories(
                                    props.input.value,
                                    1
                                  )
                                }
                              >
                                Copy to all
                              </Button>
                            </>
                          )}
                        />
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <Field
                          name={`cate-${category.value}-product2`}
                          label="Product 2"
                          component={props => (
                            <>
                              <Select
                                value={props.input.value}
                                onChange={props.input.onChange}
                                onBlur={() =>
                                  props.input.onBlur(props.input.value)
                                }
                                options={listProducts}
                                placeholder="Select product"
                              />
                              <Button
                                size="small"
                                color="secondary"
                                onClick={() =>
                                  this.copyProductToAllCategories(
                                    props.input.value,
                                    2
                                  )
                                }
                              >
                                Copy to all
                              </Button>
                            </>
                          )}
                        />
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <Field
                          name={`cate-${category.value}-product3`}
                          label="Product 3"
                          component={props => (
                            <>
                              <Select
                                value={props.input.value}
                                onChange={props.input.onChange}
                                onBlur={() =>
                                  props.input.onBlur(props.input.value)
                                }
                                options={listProducts}
                                placeholder="Select product"
                              />
                              <Button
                                size="small"
                                color="secondary"
                                onClick={() =>
                                  this.copyProductToAllCategories(
                                    props.input.value,
                                    3
                                  )
                                }
                              >
                                Copy to all
                              </Button>
                            </>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <Dialog
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.deleteAllProductsModalOpen}
              onClose={this.handleCloseDeleteAllProductsModal}
            >
              <DialogTitle>
                Are you sure you want to delete all products for this retailers
                group?
              </DialogTitle>
              <DialogContent variant="subtitle1" id="simple-modal-description">
                This action is not recoverable
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleDeleteAllProducts}
                >
                  Delete all products
                </Button>
                <Button
                  variant="contained"
                  color=""
                  onClick={this.handleCloseDeleteAllProductsModal}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </CardContent>
        </form>
      </Card>
    )
  }
}

const mapStateToProps = ({ retailersGroup }) => ({
  categories: retailersGroup.categories,
  products: retailersGroup.products,
  savingProducts: retailersGroup.savingProducts,
  initialValues: retailersGroup.retailersGroupProducts,
  record: retailersGroup.record,
})

const mapDispatchToActions = { ...retailersGroupActions }

const styles = () => ({
  overlay,
  loadingSpinner,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToActions
  )(
    reduxForm({
      form: 'retailersGroupProductForm',
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(RetailersGroupProductForm)
  )
)
