import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  Fade,
  CircularProgress,
  Button,
  CardActions,
  withStyles,
} from '@material-ui/core'

import { overlay, loadingSpinner } from '../../variables/styles'

import RegularCard from '../Cards/RegularCard.js'
import masterActions from 'actions/master.actions'
import CustomTextField from 'components/Input/CustomTextField'

export const fields = [
  'object_id',
  'masterName',
  'categoryType',
  'commonName',
  'masterProduct',
]

const onSubmit = (values, dispatch, props) => {
  values.object_id = props.initialValues.object_id
  props.save(values)
}

class MasterForm extends Component {
  componentDidMount() {
    this.props.get(this.props.match.params.id)
  }
  hideAlert(event) {
    this.props.hideAlert()
  }
  render() {
    const { record, handleSubmit, classes } = this.props
    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') +
      (record !== null && record.get('masterName')
        ? record.get('masterName')
        : '')
    return (
      <div>
        <Fade in={this.props.fetching}>
          <div
            className={classes.overlay}
            style={{ zIndex: this.props.fetching ? 10 : 0 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <RegularCard
          cardTitle={title}
          content={
            <form onSubmit={handleSubmit(this.submit.bind(this))}>
              <div>
                <Field
                  name="sharedCredits"
                  component={CustomTextField}
                  label="Shared Credits"
                />
              </div>

              <div>
                <Field
                  name="signupCredits"
                  component={CustomTextField}
                  label="Sign-up Credits"
                />
              </div>
              <div>
                <Field
                  name="signupCreditsForSharedUser"
                  component={CustomTextField}
                  label="Sign-up Credits For SharedUser"
                />
              </div>
              <div>
                <Field
                  name="GCHomePageLink"
                  component={CustomTextField}
                  label="GC Home Page Link"
                />
              </div>

              <div>
                <Field
                  name="GardenDesignLink"
                  component={CustomTextField}
                  label="Garden Design Link"
                />
              </div>
              <div>
                <Field
                  name="CareCalendarLink"
                  component={CustomTextField}
                  label="Care Calendar Link"
                />
              </div>
              <div>
                <Field
                  name="PSHomePageLink"
                  component={CustomTextField}
                  label="PS Home Page Link"
                />
              </div>

              <div>
                <Field
                  name="PSGardenSmarterLink"
                  component={CustomTextField}
                  label="PS Garden Smarter Link"
                />
              </div>
              <div>
                <Field
                  name="GCGardenSmarterLink"
                  component={CustomTextField}
                  label="GC Garden Smarter Link"
                />
              </div>
              <div>
                <Field
                  name="localRetailerFilterDistance"
                  component={CustomTextField}
                  label="Local Retailer Filter Distance"
                />
              </div>

              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.props.fetching}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.history.push('/master')}
                >
                  Back to list
                </Button>
              </CardActions>
            </form>
          }
          footer={''}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  record: state.master.record,
  fetching: state.master.fetching,
  fetched: state.master.fetched,
  saving: state.master.saving,
  saved: state.master.saved,
  fields,
  initialValues: {
    object_id: state.master.record ? state.master.record.id : null,
    sharedCredits: state.master.record
      ? state.master.record.get('sharedCredits')
      : '',
    signupCredits: state.master.record
      ? state.master.record.get('signupCredits')
      : '',
    signupCreditsForSharedUser: state.master.record
      ? state.master.record.get('signupCreditsForSharedUser')
      : '',
    GCHomePageLink: state.master.record
      ? state.master.record.get('GCHomePageLink')
      : '',
    GardenDesignLink: state.master.record
      ? state.master.record.get('GardenDesignLink')
      : '',
    CareCalendarLink: state.master.record
      ? state.master.record.get('CareCalendarLink')
      : '',
    PSHomePageLink: state.master.record
      ? state.master.record.get('PSHomePageLink')
      : '',
    PSGardenSmarterLink: state.master.record
      ? state.master.record.get('PSGardenSmarterLink')
      : '',
    GCGardenSmarterLink: state.master.record
      ? state.master.record.get('GCGardenSmarterLink')
      : '',
    localRetailerFilterDistance: state.master.record
      ? state.master.record.get('localRetailerFilterDistance')
      : '',
  },
})

const mapDispatchToProps = {
  ...masterActions,
}

const styles = theme => ({
  overlay,
  loadingSpinner,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'master',
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(MasterForm)
  )
)
