import {
  COUNT_GENUS_FAILED,
  COUNT_GENUS_SUCCEED,
  COUNT_GENUS_PROCESSING,
  FETCH_GENUS_FAILED,
  FETCH_GENUS_SUCCEED,
  FETCH_GENUS_PROCESSING,
  GET_GENUS_FAILED,
  GET_GENUS_SUCCEED,
  GET_GENUS_PROCESSING,
  FETCH_GENUS_CATEGORIES_FAILED,
  FETCH_GENUS_CATEGORIES_SUCCEED,
  FETCH_GENUS_CATEGORIES_PROCESSING,
  FETCH_GENUS_PRODUCTS_FAILED,
  FETCH_GENUS_PRODUCTS_SUCCEED,
  FETCH_GENUS_PRODUCTS_PROCESSING,
  SAVE_GENUS_FAILED,
  SAVE_GENUS_SUCCEED,
  SAVE_GENUS_PROCESSING,
  DELETE_GENUS_FAILED,
  DELETE_GENUS_SUCCEED,
  DELETE_GENUS_PROCESSING,
} from '../../constants'
import Parse from '../parse'

const Genus = Parse.Object.extend('Genus')

export const genus = {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_GENUS_PROCESSING,
      })
      let query = new Parse.Query(Genus)
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('genusName', searchText.trim(), 'i')
      }
      try {
        const total = await query.count({ useMasterKey: true })
        dispatch({
          type: COUNT_GENUS_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        dispatch({
          type: COUNT_GENUS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  async select(searchText) {
    searchText = searchText || ''
    let query = new Parse.Query(Genus)
    if (searchText !== '') {
      query.matches('genusName', searchText.trim().toLowerCase(), 'i')
    }
    const results = await query.limit(5).find()
    return results.map(result => ({
      value: result.id,
      label: result.get('genusName'),
    }))
  },
  list(page, rowsPerPage, searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_GENUS_PROCESSING,
      })
      let query = new Parse.Query(Genus)
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('genusName', searchText.trim(), 'i')
      }
      query
        .include('categoryType')
        .limit(rowsPerPage)
        .skip(page * rowsPerPage)
        .descending('createdAt')
      try {
        const results = await query.find()
        var list = []
        list = results.map(r => {
          const categoryType = r.get('categoryType')
          return {
            [r.id]: {
              name: r.get('genusName'),
              categoryType: categoryType ? categoryType.get('name') : '',
              commonName: r.get('commonName'),
            },
          }
        })
        dispatch({
          type: FETCH_GENUS_SUCCEED,
          payload: {
            list: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_GENUS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_GENUS_PROCESSING,
      })
      let query = new Parse.Query(Genus)
      query
        .include('categoryType')
        .include('genusProduct')
        .include('categoryType')
      try {
        const genus = await query.get(id)
        const categoryType = genus.get('categoryType')
        const genusProduct = genus.get('genusProduct')
        dispatch({
          type: GET_GENUS_SUCCEED,
          payload: {
            record: {
              object_id: genus.id,
              genusName: genus.get('genusName'),
              categoryType: {
                value: categoryType ? categoryType.id : '',
                label: categoryType ? categoryType.get('name') : '',
              },
              commonName: genus.get('commonName'),
              genusProduct: genusProduct.map(gP => ({
                value: gP.id,
                label: gP.get('productName'),
              })),
            },
          },
        })
      } catch (error) {
        dispatch({
          type: GET_GENUS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getCategories() {
    return async dispatch => {
      dispatch({
        type: FETCH_GENUS_CATEGORIES_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('GenusCategory'))
      query.limit(10000)
      try {
        const results = await query.find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('name'),
          })
        }
        dispatch({
          type: FETCH_GENUS_CATEGORIES_SUCCEED,
          payload: {
            categories: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_GENUS_CATEGORIES_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getProducts(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_GENUS_PRODUCTS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Products'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('productName', searchText);
        query.matches('productName', searchText.trim(), 'i')
      }
      query.limit(10000)
      try {
        const results = await query.find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('productName'),
          })
        }
        dispatch({
          type: FETCH_GENUS_PRODUCTS_SUCCEED,
          payload: {
            products: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_GENUS_PRODUCTS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_GENUS_PROCESSING,
      })
      let genus = {}
      if (
        values.object_id !== undefined &&
        values.object_id !== null &&
        values.object_id !== ''
      ) {
        const genusQuery = new Parse.Query(Genus)
        try {
          genus = await genusQuery.get(values.object_id)
        } catch (err) {
          dispatch({
            type: SAVE_GENUS_FAILED,
            payload: {
              error: `Error: ${err.code} ${err.message}`,
            },
          })
        }
      } else {
        genus = new Genus()
      }
      genus.set({
        genusName: values.genusName,
        commonName: values.commonName,
      })

      if (values.categoryType && values.categoryType.value) {
        let categoryQuery = new Parse.Query(
          Parse.Object.extend('GenusCategory')
        )
        try {
          let categoryType = await categoryQuery.get(values.categoryType.value)
          genus.set({
            categoryType: categoryType.toPointer(),
          })
        } catch (err) {
          dispatch({
            type: SAVE_GENUS_FAILED,
            payload: {
              error: `Error: ${err.code} ${err.message}`,
            },
          })
        }
      }

      if (values.genusProduct) {
        const productQuery = new Parse.Query(Parse.Object.extend('Products'))
        const genusProduct = await productQuery
          .containedIn('objectId', values.genusProduct.map(gP => gP.value))
          .find({ useMasterKey: true })
        genus.set('genusProduct', genusProduct.map(gP => gP.toPointer()))
      }

      try {
        await genus.save(null)
        dispatch({
          type: SAVE_GENUS_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: SAVE_GENUS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_GENUS_PROCESSING,
      })
      let query = new Parse.Query(Genus)
      try {
        const genera = await query.containedIn('objectId', ids).find()
        await Parse.Object.destroyAll(genera)
        dispatch({
          type: DELETE_GENUS_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: DELETE_GENUS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
}
