import {
  COUNT_RETAILER_FAILED,
  COUNT_RETAILER_SUCCEED,
  COUNT_RETAILER_PROCESSING,
  FETCH_RETAILER_FAILED,
  FETCH_RETAILER_SUCCEED,
  FETCH_RETAILER_PROCESSING,
  GET_RETAILER_FAILED,
  GET_RETAILER_SUCCEED,
  GET_RETAILER_PROCESSING,
  FETCH_RETAILER_PRODUCTS_FAILED,
  FETCH_RETAILER_PRODUCTS_SUCCEED,
  FETCH_RETAILER_PRODUCTS_PROCESSING,
  SAVE_RETAILER_FAILED,
  SAVE_RETAILER_SUCCEED,
  SAVE_RETAILER_PROCESSING,
  DELETE_RETAILER_FAILED,
  DELETE_RETAILER_SUCCEED,
  DELETE_RETAILER_PROCESSING,
} from '../../constants'
import Parse from '../parse'

export default {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_RETAILER_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Retailers'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('name', searchText.trim(), 'i')
      }
      try {
        const total = await query.count({ useMasterKey: true })
        dispatch({
          type: COUNT_RETAILER_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        dispatch({
          type: COUNT_RETAILER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  list(page, rowsPerPage, searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_RETAILER_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Retailers'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('name', searchText.trim(), 'i')
      }
      query.limit(rowsPerPage)
      query.skip(page * rowsPerPage)
      query.descending('createdAt')
      try {
        const results = await query.find()
        var list = []
        list = results.map(r => {
          return {
            [r.id]: {
              name: r.get('name'),
              phone: r.get('phone'),
              email: r.get('email'),
              city: r.get('city'),
              state: r.get('state'),
              country: r.get('country'),
            },
          }
        })
        dispatch({
          type: FETCH_RETAILER_SUCCEED,
          payload: {
            list: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_RETAILER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_RETAILER_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Retailers'))
      try {
        const retailer = await query.get(id)
        let relation = retailer.relation('products')
        const products = await relation.query().find()
        let retailerProducts = products.map(p => ({
          value: p.id,
          label: p.get('productName'),
        }))
        const record = {
          object_id: retailer ? retailer.id : null,
          name: retailer ? retailer.get('name') : '',
          address: retailer ? retailer.get('address') : '',
          address2: retailer ? retailer.get('address2') : '',
          city: retailer ? retailer.get('city') : '',
          state: retailer ? retailer.get('state') : '',
          country: retailer ? retailer.get('country') : '',
          zip: retailer ? retailer.get('zip') : '',
          phone: retailer ? retailer.get('phone') : '',
          email: retailer ? retailer.get('email') : '',
          fax: retailer ? retailer.get('fax') : '',
          url: retailer ? retailer.get('url') : '',
          latitude: retailer ? retailer.get('latitude') : '',
          longitude: retailer ? retailer.get('longitude') : '',
          products: retailerProducts,
        }
        dispatch({
          type: GET_RETAILER_SUCCEED,
          payload: {
            record,
            retailerProducts,
          },
        })
      } catch (error) {
        dispatch({
          type: GET_RETAILER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getProducts(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_RETAILER_PRODUCTS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Products'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('productName', searchText);
        query.matches('productName', searchText.trim(), 'i')
      }
      query.limit(10000)

      try {
        const results = await query.find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('productName'),
          })
        }
        dispatch({
          type: FETCH_RETAILER_PRODUCTS_SUCCEED,
          payload: {
            products: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_RETAILER_PRODUCTS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_RETAILER_PROCESSING,
      })
      let retailerModel = new Parse.Object.extend('Retailers')
      let retailer = new retailerModel()
      if (
        values.object_id !== undefined &&
        values.object_id !== null &&
        values.object_id !== ''
      ) {
        retailer.id = values.object_id
      }
      for (var k in values) {
        if (k !== 'object_id') {
          if (k === 'products' && values[k]) {
            let list = values[k]
            // Remove old products
            let oldProducts = values.oldProducts.filter(function(item) {
              return list.indexOf(item) === -1
            })
            for (var i = 0; i < oldProducts.length; i++) {
              let productModel = new Parse.Object.extend('Products')
              let product = new productModel()
              product.id = oldProducts[i]
              retailer.relation('products').remove(product)
            }
            // Add selected products
            for (var j = 0; j < list.length; j++) {
              let productModel = new Parse.Object.extend('Products')
              let product = new productModel()
              product.id = list[j]
              retailer.relation('products').add(product)
            }
            retailer.save()
          } else {
            retailer.set(k, values[k])
          }
        }
      }
      try {
        await retailer.save(null)
        dispatch({
          type: SAVE_RETAILER_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: SAVE_RETAILER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_RETAILER_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Retailers'))
      try {
        const retailers = await query.containedIn('objectId', ids).find()
        await Parse.Object.destroyAll(retailers)
        dispatch({
          type: DELETE_RETAILER_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: DELETE_RETAILER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
}
