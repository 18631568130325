import {
  COUNT_MASTER_FAILED,
  COUNT_MASTER_SUCCEED,
  COUNT_MASTER_PROCESSING,
  FETCH_MASTER_FAILED,
  FETCH_MASTER_SUCCEED,
  FETCH_MASTER_PROCESSING,
  GET_MASTER_FAILED,
  GET_MASTER_SUCCEED,
  GET_MASTER_PROCESSING,
  SAVE_MASTER_FAILED,
  SAVE_MASTER_SUCCEED,
  SAVE_MASTER_PROCESSING,
  DELETE_MASTER_FAILED,
  DELETE_MASTER_SUCCEED,
  DELETE_MASTER_PROCESSING,
  HIDE_ALERT,
} from '../constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorMessage: '',
  list: [],
  total: 0,
  categories: [],
  products: [],
  record: null,
}

const master = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_MASTER_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_MASTER_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_MASTER_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
      }
    case GET_MASTER_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case GET_MASTER_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_MASTER_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
      }    
    case COUNT_MASTER_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_MASTER_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_MASTER_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }    
    case SAVE_MASTER_FAILED:
      return {
        ...state,
        saving: false,
        saved: true,
        errorMessage: action.payload.error,
      }
    case SAVE_MASTER_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_MASTER_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_MASTER_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_MASTER_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_MASTER_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        errorMessage: '',
        deleted: false,
      }
    default:
      return state
  }
}

export default master
