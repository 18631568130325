export const COUNT_RETAILER_PROCESSING = 'COUNT_RETAILER_PROCESSING'
export const COUNT_RETAILER_SUCCEED = 'COUNT_RETAILER_SUCCEED'
export const COUNT_RETAILER_FAILED = 'COUNT_RETAILER_FAILED'
export const FETCH_RETAILER_PROCESSING = 'FETCH_RETAILER_PROCESSING'
export const FETCH_RETAILER_SUCCEED = 'FETCH_RETAILER_SUCCEED'
export const FETCH_RETAILER_FAILED = 'FETCH_RETAILER_FAILED'
export const GET_RETAILER_PROCESSING = 'GET_RETAILER_PROCESSING'
export const GET_RETAILER_SUCCEED = 'GET_RETAILER_SUCCEED'
export const GET_RETAILER_FAILED = 'GET_RETAILER_FAILED'
export const FETCH_RETAILER_PRODUCTS_PROCESSING = 'FETCH_RETAILER_PRODUCTS_PROCESSING'
export const FETCH_RETAILER_PRODUCTS_SUCCEED = 'FETCH_RETAILER_PRODUCTS_SUCCEED'
export const FETCH_RETAILER_PRODUCTS_FAILED = 'FETCH_RETAILER_PRODUCTS_FAILED'
export const SAVE_RETAILER_PROCESSING = 'SAVE_RETAILER_PROCESSING'
export const SAVE_RETAILER_SUCCEED = 'SAVE_RETAILER_SUCCEED'
export const SAVE_RETAILER_FAILED = 'SAVE_RETAILER_FAILED'
export const DELETE_RETAILER_PROCESSING = 'DELETE_RETAILER_PROCESSING'
export const DELETE_RETAILER_SUCCEED = 'DELETE_RETAILER_SUCCEED'
export const DELETE_RETAILER_FAILED = 'DELETE_RETAILER_FAILED'
export const HIDE_ALERT = 'HIDE_ALERT'
