import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  Button,
  CardActions,
  Paper,
  Grid,
  Typography,
  Divider,
  CircularProgress,
  Fade,
  withStyles,
} from '@material-ui/core'
import Select from 'react-select'
import Gallery from 'react-photo-gallery'
import Loader from 'react-loader'
import Image from 'react-image'

import { plant as plantActions } from '../../actions/plant.actions'
import RegularCard from '../Cards/RegularCard.js'
import SelectedImage from '../../utils/SelectedImage'

import { overlay, loadingSpinner } from '../../variables/styles'
import CustomSnackbar from '../CustomSnackbar'
import CustomCheckbox from 'components/Input/CustomCheckbox'
import formPropTypes from '../../constants/formPropTypes'
import CustomTextField from 'components/Input/CustomTextField'
import CustomImageUpload from 'components/Input/CustomImageUpload'

export const fields = [
  'object_id',
  'latinName',
  'commonName',
  'plantDescription',
  'alexaSpokenDescription',
  'AlexaSearch',
  'heightAndWidth',
  'bloomSeason',
  'lightRecommendation',
  'wateringRecommendation',
  'pruningNeeds',
  'hardiness',
  'usdaUkPlantin`gZones',
  'timeOfYearToPlant',
  'soilTypeRecommendation',
  'buyURL',
]

const overriddenStyles = { 
  option: (provided, state) => (
    { 
      color: state.isFocused ? '#ffffff' : '#434343', 
      fontWeight: state.isFocused ? 'bold' : 'normal',
      backgroundColor: state.isFocused ? '#434343' : '#ffffff'
    }
  )
}

// @TODO: Add loading spinner (see CategoryForm)

class PlantForm extends Component {
  static propTypes = {
    ...formPropTypes,
  }

  submit(values) {
    values.object_id = this.props.initialValues.object_id
    this.props.save(values)
  }
  componentDidMount() {
    this.props.getGenus()
    this.props.get(this.props.match.params.id)
  }
  selectPhoto(event, obj) {
    this.props.selectSnappPhoto(obj.photo.alt, this.props.photos)
  }
  savePhoto() {
    let photo = this.props.photos.find(x => x.selected === true)
    if (photo && this.props.record && photo.src && photo.alt) {
      this.props.saveSnappPhoto(this.props.record.id, photo.alt)
    }
  }
  hideAlert() {
    this.props.reset()
  }

  handleCloseError = () => {
    this.props.hideAlert()
  }
  backToList() {
    this.props.hideAlert()
    this.props.history.push('/plant')
  }
  render() {
    const {
      record,
      genus,
      handleSubmit,
      saved,
      error,
      classes,
      photos,
      plantPhotos,
      savedSnappPhoto,
      saving,
      savingSnappPhoto,
    } = this.props
    const title = this.props.match.params.id
      ? 'Edit ' + record.commonName
      : 'Add new'
    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={error}
          message={error && error.message}
          handleClose={() => this.handleCloseError()}
        />
        <CustomSnackbar
          variant="success"
          open={saved || savedSnappPhoto}
          message={'Saved'}
          autoHideDuration={1000}
          onClose={() => this.hideAlert()}
        />
        <Fade in={this.props.fetching}>
          <div
            className={classes.overlay}
            style={{ zIndex: this.props.fetching ? 10 : 0 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        {this.props.match.params.id ? (
          <Grid container spacing={24}>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                <Typography
                  variant="headline"
                  className={classes.headline}
                  gutterBottom
                >
                  {title}
                </Typography>
                <Divider style={{ marginBottom: '20px' }} />
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                  <div>
                    <Field
                      name="latinName"
                      component={CustomTextField}
                      label="Common Name"
                    />
                  </div>
                  <div>
                    <Field
                      name="commonName"
                      component={CustomTextField}
                      label="Latin Name"
                    />
                  </div>
                  <div>
                    <label>Genus</label>
                    <Field
                      name="genus"
                      label="Genus"
                      component={props => (
                        <Select
                          value={props.input.value}
                          onChange={props.input.onChange}
                          onBlur={() => props.input.onBlur(props.input.value)}
                          options={genus}
                          placeholder="Select a genus"
                          simpleValue
                          styles={overriddenStyles}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Field
                      multiline={true}
                      name="plantDescription"
                      component={CustomTextField}
                      label="Plant Description"
                    />
                  </div>
                  <div>
                    <Field
                      multiline={true}
                      name="alexaSpokenDescription"
                      component={CustomTextField}
                      label="Alexa Spoken Description"
                    />
                  </div>
                  <div>
                    <Field
                      multiline={true}
                      name="AlexaSearch"
                      component={CustomTextField}
                      label="Alexa Search Term"
                    />
                  </div>
                  <div>
                    <Field
                      multiline={true}
                      name="lightRecommendation"
                      component={CustomTextField}
                      label="Light recommendation"
                    />
                  </div>
                  <div>
                    <Field
                      multiline={true}
                      name="wateringRecommendation"
                      component={CustomTextField}
                      label="Watering recommendation"
                    />
                  </div>
                  <div>
                    <Field
                      multiline={true}
                      name="pruningNeeds"
                      component={CustomTextField}
                      label="Pruning needs"
                    />
                  </div>

                  <div>
                    <Field
                      multiline={true}
                      name="hardiness"
                      component={CustomTextField}
                      label="Hardiness"
                    />
                  </div>
                  <div>
                    <Field
                      multiline={true}
                      name="usdaUkPlantingZones"
                      component={CustomTextField}
                      label="USDA UK Planting Zones"
                    />
                  </div>
                  <div>
                    <Field
                      multiline={true}
                      name="timeOfYearToPlant"
                      component={CustomTextField}
                      label="Time of year to plant"
                    />
                  </div>
                  <div>
                    <Field
                      multiline={true}
                      name="soilTypeRecommendation"
                      component={CustomTextField}
                      label="Soil type recommendation"
                    />
                  </div>
                  <div>
                    <Field
                      multiline={true}
                      name="buyURL"
                      component={CustomTextField}
                      label="Buy URL"
                    />
                  </div>
                  <div>
                    <Field
                      name="visible"
                      component={CustomCheckbox}
                      label="Visible"
                    />
                  </div>
                  <div>
                    <div>
                      <label>Upload new Photo</label>
                    </div>
                    <Field component={CustomImageUpload} name="plantImage" />
                  </div>
                  {plantPhotos ? (
                    <div>
                      <div>
                        <label>Current Photo</label>
                      </div>
                      {plantPhotos.map(function(photo, i) {
                        return (
                          <Image
                            src={photo.thumbImage}
                            alt={title}
                            style={{ maxWidth: 72, marginRight: 10 }}
                            key={i}
                          />
                        )
                      })}
                    </div>
                  ) : null}

                  <CardActions>
                    <Loader loaded={!saving}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={this.props.fetching}
                        className={classes.button}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.backToList.bind(this)}
                        className={classes.button}
                      >
                        Back to list
                      </Button>
                    </Loader>
                  </CardActions>
                </form>
              </Paper>
            </Grid>
            <Grid item xs={7}>
              <Paper className={classes.paper}>
                <Grid container spacing={0}>
                  <Grid item xs={7}>
                    <Typography
                      variant="headline"
                      className={classes.headline}
                      gutterBottom
                    >
                      Snapp Photos
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Loader loaded={!savingSnappPhoto}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.savePhoto}
                        onClick={this.savePhoto.bind(this)}
                      >
                        Save Photo
                      </Button>
                    </Loader>
                  </Grid>
                </Grid>

                <Divider style={{ marginBottom: '20px' }} />
                <Gallery
                  photos={photos}
                  margin={5}
                  columns={5}
                  onClick={this.selectPhoto.bind(this)}
                  ImageComponent={SelectedImage}
                />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <RegularCard
            cardTitle={'Add new plant'}
            content={
              <form onSubmit={handleSubmit(this.submit.bind(this))}>
                <div>
                  <Field
                    name="latinName"
                    component={CustomTextField}
                    label="Common Name"
                  />
                </div>
                <div>
                  <Field
                    name="commonName"
                    component={CustomTextField}
                    label="Latin Name"
                  />
                </div>
                <div>
                  <label>Genus</label>
                  <Field
                    name="genus"
                    label="Genus"
                    component={props => (
                      <Select
                        value={props.input.value}
                        onChange={props.input.onChange}
                        options={genus}
                        placeholder="Select a genus"
                        simpleValue
                      />
                    )}
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="plantDescription"
                    component={CustomTextField}
                    label="Plant Description"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="alexaSpokenDescription"
                    component={CustomTextField}
                    label="Alexa Spoken Description"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="AlexaSearch"
                    component={CustomTextField}
                    label="Alexa Search Term"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="lightRecommendation"
                    component={CustomTextField}
                    label="Light recommendation"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="wateringRecommendation"
                    component={CustomTextField}
                    label="Watering recommendation"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="pruningNeeds"
                    component={CustomTextField}
                    label="Pruning needs"
                  />
                </div>

                <div>
                  <Field
                    multiline={true}
                    name="hardiness"
                    component={CustomTextField}
                    label="Hardiness"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="usdaUkPlantingZones"
                    component={CustomTextField}
                    label="USDA UK Planting Zones"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="timeOfYearToPlant"
                    component={CustomTextField}
                    label="Time of year to plant"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="soilTypeRecommendation"
                    component={CustomTextField}
                    label="Soil type recommendation"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="buyURL"
                    component={CustomTextField}
                    label="Buy URL"
                  />
                </div>
                <CardActions>
                  <Loader loaded={!saving}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={this.props.fetching}
                      className={classes.button}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.backToList.bind(this)}
                      className={classes.button}
                    >
                      Back to list
                    </Button>
                  </Loader>
                </CardActions>
              </form>
            }
            footer={''}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ plant }) => ({
  record: plant.record,
  genus: plant.genus,
  photos: plant.photos,
  plantPhotos: plant.plantPhotos,
  fetching: plant.fetching,
  fetched: plant.fetched,
  saving: plant.saving,
  saved: plant.saved,
  savingSnappPhoto: plant.savingSnappPhoto,
  savedSnappPhoto: plant.savedSnappPhoto,
  fields,
  initialValues: plant.record,
})

const mapDispatchToProps = { ...plantActions }

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  savePhoto: {
    float: 'right',
  },
  headline: {
    color: '#25A652',
  },
  button: {
    margin: theme.spacing(1),
  },
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      // a unique name for the form
      form: 'plant',
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(PlantForm)
  )
)
