import { snapp as snappAPI } from '../api'
import { SELECT_PLANT, SELECT_USER, HIDE_ALERT } from '../constants'

export const snapp = {
  fetch(page, rowsPerPage, query) {
    return dispatch => {
      return dispatch(snappAPI.fetch(page, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(snappAPI.get(id))
    }
  },
  getUsers(query) {
    return dispatch => {
      return dispatch(snappAPI.getUsers(query))
    }
  },
  count(query) {
    return dispatch => {
      return dispatch(snappAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(snappAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      return dispatch(snappAPI.delete(id))
    }
  },
  assignPlant(id, plant, comment) {
    return dispatch => {
      return dispatch(snappAPI.assignPlant(id, plant, comment))
    }
  },
  assignExpert(id, expert, comment) {
    return dispatch => {
      return dispatch(snappAPI.assignExpert(id, expert, comment))
    }
  },
  selectPlant(plant) {
    return dispatch => {
      return dispatch({
        type: SELECT_PLANT,
        payload: {
          plant,
        },
      })
    }
  },
  selectUser(user) {
    return dispatch => {
      return dispatch({
        type: SELECT_USER,
        payload: {
          user,
        },
      })
    }
  },
  hideAlert() {
    return dispatch => {
      return dispatch({
        type: HIDE_ALERT,
      })
    }
  },
}
