import { genusCareInfo as genusCareInfoAPI } from '../api'
import { HIDE_ALERT } from '../constants'
export const genusCareInfo = {
  fetch(page, rowsPerPage, query) {
    return dispatch => {
      dispatch(genusCareInfoAPI.fetch(page, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      dispatch(genusCareInfoAPI.get(id))
    }
  },
  getGenera(query) {
    return dispatch => {
      dispatch(genusCareInfoAPI.getGenera(query))
    }
  },
  getMonths(query) {
    return dispatch => {
      dispatch(genusCareInfoAPI.getMonths(query))
    }
  },
  getRegions(query) {
    return dispatch => {
      dispatch(genusCareInfoAPI.getRegions(query))
    }
  },
  getProducts(query) {
    return dispatch => {
      dispatch(genusCareInfoAPI.getProducts(query))
    }
  },
  getIcons(query) {
    return dispatch => {
      dispatch(genusCareInfoAPI.getIcons(query))
    }
  },
  count(query) {
    return dispatch => {
      dispatch(genusCareInfoAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      dispatch(genusCareInfoAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      dispatch(genusCareInfoAPI.delete(id))
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_ALERT
      })
    }
  }
}
