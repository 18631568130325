export const COUNT_SNAPP_PROCESSING = 'COUNT_SNAPP_PROCESSING'
export const COUNT_SNAPP_SUCCEED = 'COUNT_SNAPP_SUCCEED'
export const COUNT_SNAPP_FAILED = 'COUNT_SNAPP_FAILED'
export const FETCH_SNAPP_PROCESSING = 'FETCH_SNAPP_PROCESSING'
export const FETCH_SNAPP_SUCCEED = 'FETCH_SNAPP_SUCCEED'
export const FETCH_SNAPP_FAILED = 'FETCH_SNAPP_FAILED'
export const GET_SNAPP_PROCESSING = 'GET_SNAPP_PROCESSING'
export const GET_SNAPP_SUCCEED = 'GET_SNAPP_SUCCEED'
export const GET_SNAPP_FAILED = 'GET_SNAPP_FAILED'
export const FETCH_SNAPP_USERS_PROCESSING = 'FETCH_SNAPP_USERS_PROCESSING'
export const FETCH_SNAPP_USERS_SUCCEED = 'FETCH_SNAPP_USERS_SUCCEED'
export const FETCH_SNAPP_USERS_FAILED = 'FETCH_SNAPP_USERS_FAILED'
export const SAVE_SNAPP_PROCESSING = 'SAVE_SNAPP_PROCESSING'
export const SAVE_SNAPP_SUCCEED = 'SAVE_SNAPP_SUCCEED'
export const SAVE_SNAPP_FAILED = 'SAVE_SNAPP_FAILED'
export const DELETE_SNAPP_PROCESSING = 'DELETE_SNAPP_PROCESSING'
export const DELETE_SNAPP_SUCCEED = 'DELETE_SNAPP_SUCCEED'
export const DELETE_SNAPP_FAILED = 'DELETE_SNAPP_FAILED'
export const HIDE_ALERT = 'HIDE_ALERT' //it is not used in snapp.api.js. it is used in snapp.reducers.js
export const SELECT_PLANT= 'SELECT_PLANT' //it is not used in snapp.api.js. it is used in snapp.reducers.js
export const SELECT_USER= 'SELECT_USER'//it is not used in snapp.api.js. it is used in snapp.reducers.js
export const ASSIGN_PLANT_PROCESSING = 'ASSIGN_PLANT_PROCESSING'
export const ASSIGN_PLANT_SUCCEED = 'ASSIGN_PLANT_SUCCEED'
export const ASSIGN_PLANT_FAILED = 'ASSIGN_PLANT_FAILED'
export const ASSIGN_EXPERT_PROCESSING = 'ASSIGN_EXPERT_PROCESSING'
export const ASSIGN_EXPERT_SUCCEED = 'ASSIGN_EXPERT_SUCCEED'
export const ASSIGN_EXPERT_FAILED = 'ASSIGN_EXPERT_FAILED'
