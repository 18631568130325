import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'
import utils from '../../utils'

class CustomFileInput extends Component {
  state = {
    uploading: false,
  }
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    label: PropTypes.string,
    accept: PropTypes.string,
  }

  static defaultProps = {
    accept: '*',
  }

  fileInputRef = React.createRef()

  uploadFile = async () => {
    this.uploading = true
    let fileToUpload = this.fileInputRef.current.files[0]
    if (fileToUpload) {
      const base64data = await utils.toBase64(fileToUpload)
      this.uploading = false
      this.props.input.onChange(base64data)
    } else {
      this.uploading = false
    }
  }

  render() {
    const { input, label, touched, invalid, accept } = this.props
    return (
      <>
        <input
          onChange={this.uploadFile}
          type="file"
          ref={this.fileInputRef}
          style={{ display: 'none' }}
          accept={accept}
        />
        {this.uploading && <CircularProgress />}
        <Button
          onClick={() => this.fileInputRef.current.click()}
          variant="contained"
          color="secondary"
        >
          {input.value ? 'Replace' : label}
        </Button>
        {touched && invalid && <p>Required</p>}
      </>
    )
  }
}

export default CustomFileInput
