import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  Button,
  CardActions,
  CircularProgress,
  Fade,
  withStyles,
} from '@material-ui/core'

import RegularCard from '../Cards/RegularCard.js'
import { category } from '../../actions'
import { overlay, loadingSpinner } from '../../variables/styles'
import CustomTextField from 'components/Input/CustomTextField.js'
import formPropTypes from '../../constants/formPropTypes'
import CustomSnackbar from 'components/CustomSnackbar/index.js'
import CustomMultiSelect from 'components/Input/CustomMultiSelect.js'

export const fields = [
  'object_id',
  'name',
  'categoryDescription',
  'recommendedProducts',
]

class CategoryForm extends Component {
  static propTypes = {
    ...formPropTypes,
  }

  submit(values) {
    values.object_id = this.props.initialValues.object_id
    this.props.dispatch(category.save(values))
  }
  componentDidMount() {
    this.props.dispatch(category.getProducts())
    this.props.dispatch(category.get(this.props.match.params.id))
  }
  hideAlert() {
    this.props.dispatch(category.hideAlert())
    this.props.record
      ? window.location.reload()
      : this.props.history.push('/categories')
  }
  render() {
    const {
      record,
      classes,
      products,
      handleSubmit,
      submitting,
      submitSucceeded,
      error,
    } = this.props
    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') +
      (record !== null ? record.get('name') : '')
    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={Boolean(error)}
          message={error && error.message}
          handleClose={() => this.handleCloseError()}
        />
        <CustomSnackbar
          variant="info"
          open={Boolean(submitting)}
          message="Saving"
        />
        <CustomSnackbar
          variant="success"
          open={Boolean(submitSucceeded)}
          message="Saved"
          onClose={() => {}}
        />
        <Fade in={this.props.fetching}>
          <div
            className={classes.overlay}
            style={{ zIndex: this.props.fetching ? 10 : 0 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <RegularCard
          cardTitle={title}
          content={
            <form onSubmit={handleSubmit(this.submit.bind(this))}>
              <div>
                <Field
                  name="name"
                  component={CustomTextField}
                  label="Category Name"
                />
              </div>
              <div>
                <Field
                  multiline={true}
                  name="categoryDescription"
                  component={CustomTextField}
                  label="Category Description"
                />
              </div>
              <div>
                <label>Product</label>
                <Field
                  name="recommendedProducts"
                  label="Products"
                  options={products}
                  placeholder="Select products"
                  component={CustomMultiSelect}
                />
              </div>

              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.props.fetching}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.history.push('/categories')}
                >
                  Back to list
                </Button>
              </CardActions>
            </form>
          }
          footer={''}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ record }) => ({
  record: category.record,
  products: category.products,
  fetching: category.fetching,
  fetched: category.fetched,
  saving: category.saving,
  saved: category.saved,
  fields,
  initialValues: category.record,
})

const styles = theme => ({
  overlay,
  loadingSpinner,
})

export default withStyles(styles)(
  connect(mapStateToProps)(
    reduxForm({
      // a unique name for the form
      form: 'category',
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(CategoryForm)
  )
)
