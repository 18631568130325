import {
  COUNT_BARCODE_FAILED,
  COUNT_BARCODE_SUCCEED,
  COUNT_BARCODE_PROCESSING,
  FETCH_BARCODE_FAILED,
  FETCH_BARCODE_SUCCEED,
  FETCH_BARCODE_PROCESSING,
  GET_BARCODE_PROCESSING,
  GET_BARCODE_FAILED,
  GET_BARCODE_SUCCEED,
  FETCH_BARCODE_RETAILERSGROUPS_FAILED,
  FETCH_BARCODE_RETAILERSGROUPS_SUCCEED,
  FETCH_BARCODE_RETAILERSGROUPS_PROCESSING,
  SAVE_BARCODE_FAILED,
  SAVE_BARCODE_SUCCEED,
  SAVE_BARCODE_PROCESSING,
  DELETE_BARCODE_FAILED,
  DELETE_BARCODE_SUCCEED,
  DELETE_BARCODE_PROCESSING,
  IMPORT_BARCODE_FAILED,
  IMPORT_BARCODE_SUCCEED,
  IMPORT_BARCODE_PROCESSING,
} from '../../constants'
import Parse from '../parse'
import { retailersGroup as retailersGroupAPI } from './retailersGroup.api'

const parseObject = 'Barcode'

export const barcode = {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_BARCODE_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('barcode', searchText.trim(), 'i')
      }
      try {
        const total = await query.count({ useMasterKey: true })
        return dispatch({
          type: COUNT_BARCODE_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (err) {
        return dispatch({
          type: COUNT_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
      }
    }
  },
  fetch(page, rowsPerPage, searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_BARCODE_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      query.include('plant').include('retailersGroup')
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('barcode', searchText.trim(), 'i')
      }
      try {
        const results = await query
          .limit(rowsPerPage)
          .skip(page * rowsPerPage)
          .descending('createdAt')
          .find()
        var list = []
        results.forEach(async r => {
          const plant = r.get('plant')
          const retailersGroups = await r
            .relation('retailersGroups')
            .query()
            .find()
          const retailersGroupNames = retailersGroups
            ? retailersGroups
                .map(rG => (rG && rG.get('name') ? rG.get('name') : ''))
                .join(', ')
            : ''
          list.push({
            [r.id]: {
              barcode: r.get('barcode'),
              plantCommonName:
                plant && plant.get('commonName') ? plant.get('commonName') : '',
              plantLatinName:
                plant && plant.get('latinName') ? plant.get('latinName') : '',
              retailersGroups: retailersGroupNames || '',
            },
          })
        })
        return dispatch({
          type: FETCH_BARCODE_SUCCEED,
          payload: {
            list: list,
          },
        })
      } catch (err) {
        return dispatch({
          type: FETCH_BARCODE_FAILED,
          payload: {
            error: `Error: ${err.message}`,
          },
        })
      }
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_BARCODE_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      try {
        const barcode = await query
          .include('plant')
          .include('retailersGroup')
          .include('retailersGroups')
          .get(id)
        const plant = {
          value: barcode.get('plant') ? barcode.get('plant').id : '',
          label: barcode.get('plant')
            ? barcode.get('plant').get('latinName')
            : '',
        }
        const retailersGroupsParse = await barcode
          .relation('retailersGroups')
          .query()
          .find()
        const retailersGroups = retailersGroupsParse.map(rG => ({
          value: rG.id,
          label: rG.get('name'),
        }))
        const record = {
          id: barcode.get('plant').id,
          name: barcode.get('plant').get('commonName'),
          barcode: barcode.get('barcode'),
          plant,
          retailersGroups,
        }
        const plantDescription = barcode.get('plant')
          ? barcode.get('plant').get('plantDescription')
          : ''
        return dispatch({
          type: GET_BARCODE_SUCCEED,
          payload: { record, plantDescription },
        })
      } catch (err) {
        return dispatch({
          type: GET_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
      }
    }
  },
  getRetailersGroups() {
    return async dispatch => {
      dispatch({
        type: FETCH_BARCODE_RETAILERSGROUPS_PROCESSING,
      })
      try {
        const list = await retailersGroupAPI.select('', 10000)
        return dispatch({
          type: FETCH_BARCODE_RETAILERSGROUPS_SUCCEED,
          payload: {
            retailersGroups: list,
          },
        })
      } catch (err) {
        return dispatch({
          type: FETCH_BARCODE_RETAILERSGROUPS_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
      }
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_BARCODE_PROCESSING,
      })
      let barcodeModel = new Parse.Object.extend(parseObject)
      let barcode = new barcodeModel()
      if (
        values.object_id !== undefined &&
        values.object_id !== null &&
        values.object_id !== ''
      ) {
        barcode.id = values.object_id
      }
      for (var k in values) {
        if (k !== 'object_id') {
          if (k === 'plant' && values[k]) {
            let plantModel = new Parse.Object.extend('Plant')
            let plant = new plantModel()
            plant.id = values[k].value
            barcode.set(k, plant)
          } else if (k === 'retailersGroup' && values[k]) {
            let retailersGroupModel = new Parse.Object.extend('RetailersGroup')
            let retailersGroup = new retailersGroupModel()
            retailersGroup.id = values[k].value
            barcode.set(k, retailersGroup)
          } else if (k === 'retailersGroups' && values[k]) {
            var list = values[k]
            var retailersGroupsRelation = barcode.relation('retailersGroups')
            for (var i = 0; i < list.length; i++) {
              let retailersGroupModel = new Parse.Object.extend(
                'RetailersGroup'
              )
              let retailersGroup = new retailersGroupModel()
              retailersGroup.id = list[i]
              retailersGroupsRelation.add(retailersGroup)
            }
          } else {
            barcode.set(k, values[k])
          }
        }
      }
      try {
        await barcode.save(null)
        return dispatch({
          type: SAVE_BARCODE_SUCCEED,
        })
      } catch (err) {
        return dispatch({
          type: SAVE_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
      }
    }
  },
  import(values) {
    return async dispatch => {
      dispatch({
        type: IMPORT_BARCODE_PROCESSING,
      })
      let barcodesFilesModel = new Parse.Object.extend('BarcodesFiles')
      let barcodesFiles = new barcodesFilesModel()
      // the file is encoded as base64
      let file = new Parse.File('barcodes.csv', { base64: values.file })
      try {
        await file.save()
      } catch (err) {
        console.error(err)
        dispatch({
          type: IMPORT_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
        throw err
      }
      barcodesFiles.set({
        date: new Date(),
        file,
        email: values.email,
      })
      if (values.retailersGroup && values.retailersGroup.value) {
        let retailersGroupModel = new Parse.Object.extend('RetailersGroup')
        let retailersGroup = new retailersGroupModel()
        retailersGroup.id = values.retailersGroup.value
        barcodesFiles.set('retailersGroup', retailersGroup)
      }
      try {
        const barcodeFileParse = await barcodesFiles.save(null)
        // run the cloud function
        await Parse.Cloud.startJob('uploadBarcode', {
          fileId: barcodeFileParse.id,
          retailerId: barcodeFileParse.get('retailersGroup').id,
        })
        return dispatch({
          type: IMPORT_BARCODE_SUCCEED,
        })
      } catch (err) {
        console.error(err)
        dispatch({
          type: IMPORT_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
        throw err
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_BARCODE_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      try {
        const barcodes = await query.containedIn('objectId', ids).find()
        await Parse.Object.destroyAll(barcodes)
        return dispatch({
          type: DELETE_BARCODE_SUCCEED,
        })
      } catch (err) {
        return dispatch({
          type: DELETE_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
      }
    }
  },
}
