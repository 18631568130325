import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Grid,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Typography,
  Divider,
  withStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
// import { Line as LineChart } from 'react-chartjs-2'
import { retailersGroup as retailersGroupActions } from '../../actions'
import { report as reportActions } from '../../actions'
import CustomSnackbar from '../CustomSnackbar'
import moment from 'moment'

class Report extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    retailerGroupsReport: PropTypes.object,
    numberIdDoneByExpert: PropTypes.func,
    retailersGroups: PropTypes.array,
    chatsByExpert: PropTypes.object,
    classes: PropTypes.object.isRequired,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
  }

  state = {
    onlyLastMonth: true,
    retailerGroupReportQuery: {
      fromDate: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      unit: 'day',
      retailerGroupId: 'none',
      outputFormat: 'csv',
    },
    chatsByExpert: {
      fromDate: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    },
  }

  handleGetRetailerGroupsReportClick = () => {
    this.props.getRetailerGroupsReport(this.state.retailerGroupReportQuery)
  }

  handleGetChatsByExpertClick = () => {
    this.props.getChatsByExpert(this.state.chatsByExpert)
  }

  handleGetNumberIdDoneByExpertClick = () => {
    this.props.numberIdDoneByExpert(this.state.onlyLastMonth)
  }

  handleChangeFromDateRetailerGroupReport = event => {
    this.setState({
      ...this.state,
      retailerGroupReportQuery: {
        ...this.state.chatsByExpert,
        fromDate: event.target.value,
      },
    })
  }

  handleChangeToDateRetailerGroupReport = event => {
    this.setState({
      ...this.state,
      retailerGroupReportQuery: {
        ...this.state.chatsByExpert,
        toDate: event.target.value,
      },
    })
  }
  handleChangeFromDateChatsByExpert = event => {
    this.setState({
      ...this.state,
      chatsByExpert: {
        ...this.state.chatsByExpert,
        fromDate: event.target.value,
      },
    })
  }
  handleChangeToDateChatsByExpert = event => {
    this.setState({
      ...this.state,
      chatsByExpert: {
        ...this.state.chatsByExpert,
        toDate: event.target.value,
      },
    })
  }
  handleSelectRetailerGroupSelectChange = event => {
    this.setState({
      ...this.state,
      retailerGroupReportQuery: {
        ...this.state.retailerGroupReportQuery,
        retailerGroupId: event.target.value,
      },
    })
  }
  handleSelectRetailerGroupFormatChange = event => {
    this.setState({
      ...this.state,
      retailerGroupReportQuery: {
        ...this.state.retailerGroupReportQuery,
        outputFormat: event.target.value,
      },
    })
  }
  handleCloseError = () => {
    this.props.hideAlert()
  }
  componentDidMount() {
    this._getRetailersGroup()
  }
  _getRetailersGroup() {
    this.props.fetch(0, -1)
  }
  handleChangeNumberIdsDoneByExpertByMonth = () => event => {
    this.setState({ onlyLastMonth: event.target.checked }, () => {
      this.props.numberIdDoneByExpert(this.state.onlyLastMonth)
    })
  }
  render() {
    const {
      numberIdDoneByExpert,
      retailersGroups,
      classes,
      error,
      errorMessage,
    } = this.props

    const csvOutput =
      this.state.retailerGroupReportQuery.outputFormat === 'csv' &&
      this.props.retailerGroupsReport.fetched ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <List>
              {Object.keys(this.props.retailerGroupsReport.data).map(key => {
                if (this.props.retailerGroupsReport.data.hasOwnProperty(key)) {
                  const data = this.props.retailerGroupsReport.data[key]
                  const csvData = new Blob([data], {
                    type: 'text/csv;charset=utf-8;',
                  })
                  const csvURL = window.URL.createObjectURL(csvData)
                  return (
                    <ListItem
                      button
                      component="a"
                      href={csvURL}
                      download={`${key}.csv`}
                      key={key}
                    >
                      <ListItemIcon>
                        <CloudDownload />
                      </ListItemIcon>
                      <ListItemText primary={key} />
                    </ListItem>
                  )
                }
                return null
              })}
            </List>
          </Grid>
        </Grid>
      ) : (
        ''
      )

    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={error}
          message={errorMessage || ''}
          onClose={this.handleCloseError}
          handleClose={this.handleCloseError}
        />
        <Card className={classes.card}>
          <CardHeader title="Retailer report" />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  id="fromDate"
                  label="From"
                  type="date"
                  onChange={this.handleChangeFromDateRetailerGroupReport}
                  value={this.state.retailerGroupReportQuery.fromDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="toDate"
                  label="To"
                  type="date"
                  onChange={this.handleChangeToDateRetailerGroupReport}
                  value={this.state.retailerGroupReportQuery.toDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  value={this.state.retailerGroupReportQuery.retailerGroupId}
                  onChange={this.handleSelectRetailerGroupSelectChange}
                  className={classes.select}
                  inputProps={{
                    name: 'retailersGroup',
                    id: 'retailers-group',
                  }}
                >
                  <MenuItem value="none">
                    <em>Select retailer</em>
                  </MenuItem>
                  {retailersGroups.map(retailersGroup => {
                    // use the id for the value (stored as the key of the object)
                    const id = Object.keys(retailersGroup)[0]
                    const name = retailersGroup[id].name
                    return (
                      <MenuItem value={id} key={id}>
                        {name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Select
                value={this.state.retailerGroupReportQuery.outputFormat}
                onChange={this.handleSelectRetailerGroupFormatChange}
                className={classes.select}
                inputProps={{
                  name: 'retailersGroupFormat',
                  id: 'retailers-group-format',
                }}
              >
                <MenuItem value="csv">
                  <em>CSV</em>
                </MenuItem>
                <MenuItem value="chart">
                  <em>Chart</em>
                </MenuItem>
              </Select>
            </Grid>
            {this.props.retailerGroupsReport.fetching && (
              <CircularProgress className={classes.progress} />
            )}
            {csvOutput}
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={this.handleGetRetailerGroupsReportClick}
              disabled={
                this.state.retailerGroupReportQuery.retailerGroupId === 'none'
              }
            >
              Get Report
            </Button>
          </CardActions>
        </Card>

        <Paper className={classes.root}>
          <Typography variant="h4" className={classes.headline} gutterBottom>
            IDs by expert
            <FormControlLabel
              style={{ float: 'right' }}
              control={
                <Checkbox
                  checked={this.state.onlyLastMonth}
                  onChange={this.handleChangeNumberIdsDoneByExpertByMonth()}
                  value="onlyLastMonth"
                />
              }
              label="only last month"
            />
          </Typography>
          <Grid container spacing={4} className={classes.buttonGrid}>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={this.handleGetNumberIdDoneByExpertClick}
              >
                Get report
              </Button>
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: '20px' }} />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>expert name</TableCell>
                <TableCell>past 24 hrs</TableCell>
                <TableCell>past week </TableCell>
                <TableCell>past month</TableCell>
                <TableCell>past year</TableCell>
                <TableCell>total</TableCell>
              </TableRow>
            </TableHead>
            {numberIdDoneByExpert ? (
              <TableBody>
                {Object.keys(numberIdDoneByExpert).map(key => {
                  return (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {key}
                      </TableCell>
                      <TableCell>
                        {numberIdDoneByExpert[key].Past24hrs}
                      </TableCell>
                      <TableCell>
                        {numberIdDoneByExpert[key].PastWeek}
                      </TableCell>
                      <TableCell>
                        {numberIdDoneByExpert[key].PastMonth}
                      </TableCell>
                      <TableCell>
                        {numberIdDoneByExpert[key].PastYear
                          ? numberIdDoneByExpert[key].PastYear
                          : ''}
                      </TableCell>
                      <TableCell>
                        {numberIdDoneByExpert[key].Total
                          ? numberIdDoneByExpert[key].Total
                          : ''}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            ) : null}
          </Table>
        </Paper>

        <Paper className={classes.root}>
          <Typography variant="h4" className={classes.headline} gutterBottom>
            Chats by expert
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={this.handleGetChatsByExpertClick}
              >
                Get Report
              </Button>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="fromDate"
                label="From"
                type="date"
                onChange={this.handleChangeFromDateChatsByExpert}
                value={this.state.chatsByExpert.fromDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="toDate"
                label="To"
                type="date"
                onChange={this.handleChangeToDateChatsByExpert}
                value={this.state.chatsByExpert.toDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          {this.props.chatsByExpert.fetched ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>expert name</TableCell>
                  <TableCell>email</TableCell>
                  <TableCell>count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(this.props.chatsByExpert.data).map(key => {
                  return (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {this.props.chatsByExpert.data[key].name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {this.props.chatsByExpert.data[key].email}
                      </TableCell>
                      <TableCell>
                        {this.props.chatsByExpert.data[key].count}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          ) : null}
          {this.props.chatsByExpert.fetching && (
            <CircularProgress className={classes.progress} />
          )}
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  retailerGroupsReport: state.report.retailerGroupsReport,
  retailersGroups: state.retailersGroup.list,
  chatsByExpert: state.report.chatsByExpert,
  error: state.report.error,
  errorMessage: state.report.errorMessage,
})

const mapDispatchToProps = {
  ...retailersGroupActions,
  ...reportActions,
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  headline: {
    color: '#25A652',
    width: '100%',
  },
  table: {
    minWidth: 700,
  },
  buttonGrid: {
    marginBottom: theme.spacing(1),
  },
  progress: {
    margin: theme.spacing(2),
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Report)
)
