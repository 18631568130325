import PropTypes from 'prop-types'

export default {
  count: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  fetch: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
}
