export const COUNT_BARCODE_PROCESSING = 'COUNT_BARCODE_PROCESSING'
export const COUNT_BARCODE_SUCCEED = 'COUNT_BARCODE_SUCCEED'
export const COUNT_BARCODE_FAILED = 'COUNT_BARCODE_FAILED'
export const FETCH_BARCODE_PROCESSING = 'FETCH_BARCODE_PROCESSING'
export const FETCH_BARCODE_SUCCEED = 'FETCH_BARCODE_SUCCEED'
export const FETCH_BARCODE_FAILED = 'FETCH_BARCODE_FAILED'
export const FETCH_BARCODE_RETAILERSGROUPS_PROCESSING = 'FETCH_BARCODE_RETAILERSGROUPS_PROCESSING'
export const FETCH_BARCODE_RETAILERSGROUPS_SUCCEED = 'FETCH_BARCODE_RETAILERSGROUPS_SUCCEED'
export const FETCH_BARCODE_RETAILERSGROUPS_FAILED = 'FETCH_BARCODE_RETAILERSGROUPS_FAILED'
export const GET_BARCODE_PROCESSING = 'GET_BARCODE_PROCESSING'
export const GET_BARCODE_SUCCEED = 'GET_BARCODE_SUCCEED'
export const GET_BARCODE_FAILED = 'GET_BARCODE_FAILED'
export const SAVE_BARCODE_PROCESSING = 'SAVE_BARCODE_PROCESSING'
export const SAVE_BARCODE_SUCCEED = 'SAVE_BARCODE_SUCCEED'
export const SAVE_BARCODE_FAILED = 'SAVE_BARCODE_FAILED'
export const DELETE_BARCODE_PROCESSING = 'DELETE_BARCODE_PROCESSING'
export const DELETE_BARCODE_SUCCEED = 'DELETE_BARCODE_SUCCEED'
export const DELETE_BARCODE_FAILED = 'DELETE_BARCODE_FAILED'
export const IMPORT_BARCODE_PROCESSING = 'IMPORT_BARCODE_PROCESSING'
export const IMPORT_BARCODE_SUCCEED = 'IMPORT_BARCODE_SUCCEED'
export const IMPORT_BARCODE_FAILED = 'IMPORT_BARCODE_FAILED'
export const HIDE_ALERT = 'HIDE_ALERT'
export const SELECT_PLANT= 'SELECT_PLANT'
