import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import SweetAlert from 'sweetalert2-react'
import debounce from 'lodash.debounce'

import { snapp as snappActions } from '../../actions/snapp.actions'
import CustomTable from '../Table/CustomTable'
import listPropTypes from 'constants/listPropTypes'

const baseUrl = '/snapps'

class Snapp extends Component {
  static propTypes = {
    ...listPropTypes,
  }

  state = {
    page: 0,
    rowsPerPage: 10,
    fields: [
      {
        id: 'snappImage',
        label: 'Image',
        type: 'image',
      },
      { id: 'user', label: 'User' },
      {
        id: 'snappType',
        label: 'Snapp Type',
      },
      {
        id: 'snappRegion',
        label: 'Region',
      },
      {
        id: 'sendForIdentification',
        label: 'Expert Identified Date',
      },
    ],
  }

  componentDidMount() {
    this.props.count()
    this._getSnapp()
  }
  _getSnapp() {
    this.props.fetch(this.state.page, this.state.rowsPerPage)
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getSnapp()
    })
  }
  handleSearch = debounce(query => {
    this.props.count(query)
    this.props.fetch(this.state.page, this.state.rowsPerPage, query)
  }, 1000)

  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getSnapp()
    })
  }
  handleDeleteAction = id => {
    this.props.delete(id)
  }
  hideAlert() {
    this.props.hideAlert()
  }
  render() {
    const { errorMessage, total, history, list } = this.props
    return (
      <div>
        <CustomTable
          title={'Snapp'}
          total={total}
          history={history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={list}
          editPath={`${baseUrl}/edit/`}
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: `${baseUrl}/edit/`,
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
        <SweetAlert
          type={errorMessage ? 'error' : 'success'}
          show={errorMessage ? true : false}
          title={errorMessage ? 'Error' : 'Notice'}
          text={errorMessage ? errorMessage : 'Deleted'}
          onConfirm={() => this.props.hideAlert()}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  total: state.snapp.total,
  list: state.snapp.list,
  deleted: state.snapp.deleted,
  errorMessage: state.snapp.errorMessage,
})

const mapDispatchToProps = { ...snappActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Snapp)
