import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Menu } from '@material-ui/icons'
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Button,
  withStyles,
} from '@material-ui/core'
import cx from 'classnames'

import userActions from 'actions/user.actions'
import headerStyle from '../../variables/styles/headerStyle.jsx'

import HeaderLinks from './HeaderLinks'

class Header extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
    dispatch: PropTypes.func.isRequired,
  }

  makeBrand() {
    var name = 'SmartPlant'
    this.props.routes.map((prop, key) => {
      if (prop.path === this.props.location.pathname) {
        name = prop.navbarName
      }
      return null
    })
    return name
  }
  handleDrawerToggle() {
    this.props.toggleSidebar()
  }

  render() {
    const { classes, color } = this.props
    const appBarClasses = cx({
      [' ' + classes[color]]: color,
    })

    return (
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Button href="#" className={classes.title}>
              {this.makeBrand()}
            </Button>
          </div>
          <Hidden smDown implementation="css">
            <HeaderLinks />
          </Hidden>
          <Hidden mdUp>
            <IconButton
              className={classes.appResponsive}
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  ...userActions,
}

export default withStyles(headerStyle)(
  connect(mapStateToProps, mapDispatchToProps)(Header)
)
