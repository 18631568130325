import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import {
  Button,
  CardActions,
  CircularProgress,
  Fade,
  withStyles,
  Grid,
} from '@material-ui/core'

import RegularCard from '../Cards/RegularCard.js'
import { retailersGroup as retailersGroupActions } from '../../actions'
import { connect } from 'react-redux'

import SweetAlert from 'sweetalert2-react'
import ProductForm from './RetailersGroupProductForm'
import Image from 'react-image'

import { overlay, loadingSpinner } from '../../variables/styles'
import CustomTextField from '../Input/CustomTextField'
import CustomImageUpload from '../Input/CustomImageUpload'
import CustomCheckbox from '../Input/CustomCheckbox.js'
import formPropTypes from '../../constants/formPropTypes'
import CustomMultiSelect from 'components/Input/CustomMultiSelect.js'

export const fields = [
  'object_id',
  'name',
  'isDefault',
  'isPartner',
  'hasStripeSubscription',
  'logo',
]

const onSubmit = (values, dispatch, props) => {
  values.object_id = props.initialValues.object_id
  values.oldProducts =
    props.retailersGroupProducts &&
    props.retailersGroupProducts.length > 0 &&
    props.retailersGroupProducts[0] !== null
      ? props.retailersGroupProducts.map(product => product.id)
      : []
  props.save(values)
}

class RetailersGroupForm extends Component {
  static propTypes = {
    ...formPropTypes,
    retailersGroupProducts: PropTypes.object.isRequired,
    getRegions: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    saveProducts: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
  }

  componentDidMount() {
    this.props.getRegions()
    this.props.getProducts()
    if (this.props.match.params.id) {
      this.props.get(this.props.match.params.id)
    }
  }
  hideAlert = () => {
    this.props.hideAlert()
  }
  saveProducts = values => {
    this.props.saveProducts(values)
  }
  render() {
    const {
      record,
      handleSubmit,
      saved,
      errorMessage,
      regions,
      classes,
      savedProducts,
    } = this.props
    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') + record.name
    const logo = record.logo
    return (
      <div>
        <Fade in={this.props.fetching}>
          <div
            className={classes.overlay}
            style={{ zIndex: this.props.fetching ? 10 : 0 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <RegularCard
          cardTitle={title}
          mediaUrl={logo}
          mediaTitle={title}
          content={
            <form onSubmit={handleSubmit}>
              <Grid container direction="column">
                <Grid item>
                  {record.logo && (
                    <Image
                      src={record.logo}
                      alt={title}
                      style={{ maxWidth: 128 }}
                    />
                  )}
                </Grid>
                <Grid item>
                  <Field
                    component={CustomImageUpload}
                    name="logo"
                    label="Upload Logo"
                  />
                </Grid>
                <Grid item>
                  <Field
                    name="name"
                    component={CustomTextField}
                    label="RetailersGroup Name"
                  />
                </Grid>
                <Grid item>
                  <Field
                    name="isDefault"
                    component={CustomCheckbox}
                    label="Is Default"
                  />
                </Grid>
                <Grid item>
                  <Field
                    name="isPartner"
                    component={CustomCheckbox}
                    label="Is Partner"
                  />
                </Grid>
                <Grid item>
                  <Field
                    name="hasStripeSubscription"
                    component={CustomCheckbox}
                    label="Has StripeSubscription"
                  />
                </Grid>
                <Grid item>
                  <Field
                    name="regions"
                    label="Regions"
                    options={regions}
                    placeholder="Select regions"
                    component={CustomMultiSelect}
                  />
                </Grid>
              </Grid>

              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.props.fetching}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.history.push('/retailersGroups')}
                >
                  Back to list
                </Button>
              </CardActions>
            </form>
          }
          footer={''}
        />
        {record ? (
          <ProductForm onSubmit={this.saveProducts} classes={classes} />
        ) : null}
        <SweetAlert
          show={saved || savedProducts || errorMessage ? true : false}
          title="Notice"
          text={errorMessage ? errorMessage : 'Saved'}
          onConfirm={this.hideAlert}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ retailersGroup }) => ({
  ...retailersGroup,
  fields,
  initialValues: retailersGroup.record,
})

const mapDispatchToProps = { ...retailersGroupActions }

const styles = theme => ({
  overlay,
  loadingSpinner,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'retailersGroup',
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(RetailersGroupForm)
  )
)
