import { retailersGroup as retailersGroupAPI } from '../api'
import { HIDE_ALERT } from '../constants'
export const retailersGroup = {
  fetch(page, rowsPerPage, query) {
    return dispatch => {
      dispatch(retailersGroupAPI.list(page, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      dispatch(retailersGroupAPI.get(id))
    }
  },
  getRegions(query) {
    return dispatch => {
      dispatch(retailersGroupAPI.getRegions(query))
    }
  },
  getProducts() {
    return dispatch => {
      dispatch(retailersGroupAPI.getProducts())
    }
  },
  count(query) {
    return dispatch => {
      dispatch(retailersGroupAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      dispatch(retailersGroupAPI.save(values))
    }
  },
  saveProducts(values) {
    return dispatch => {
      dispatch(retailersGroupAPI.saveProducts(values))
    }
  },
  delete(id) {
    return dispatch => {
      dispatch(retailersGroupAPI.delete(id))
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_ALERT
      })
    }
  }
}
