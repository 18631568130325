import { plant as plantAPI } from '../api'
import { SELECT_SNAPP_PHOTO, HIDE_ALERT } from '../constants'
export const plant = {
  fetch(page, rowsPerPage, query, genusQuery) {
    return dispatch => {
      return dispatch(plantAPI.fetch(page, rowsPerPage, query, genusQuery))
    }
  },
  get(id) {
    return dispatch => {
      dispatch(plantAPI.get(id))
    }
  },
  getGenus(query) {
    return dispatch => {
      dispatch(plantAPI.getGenus(query))
    }
  },
  getNamePlants(query) {
    return dispatch => {
      dispatch(plantAPI.getNamePlants(query))
    }
  },  
  count(query, genusQuery = '') {
    return dispatch => {
      dispatch(plantAPI.count(query, genusQuery))
    }
  },
  save(values) {
    return dispatch => {
      dispatch(plantAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      dispatch(plantAPI.delete(id))
    }
  },
  selectSnappPhoto(selectedPhoto, photos) {
    return dispatch => {
      dispatch({
        type: SELECT_SNAPP_PHOTO,
        payload: {
          photos: photos.map(
            photo =>
              photo.alt === selectedPhoto
                ? {
                    ...photo,
                    selected: true
                  }
                : {
                    ...photo,
                    selected: false
                  }
          )
        }
      })
    }
  },
  saveSnappPhoto(plantId, snappId) {
    return dispatch => {
      dispatch(plantAPI.saveSnappPhoto(plantId, snappId))
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_ALERT
      })
    }
  },
  mergePlants(primary, selected) {
    return dispatch => {
      dispatch(plantAPI.mergePlants(primary, selected))
    }
  }
}
