 import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import debounce from 'lodash.debounce'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  MergeType as MergeTypeIcon
} from '@material-ui/icons'
import { Button, FormControl, Select, MenuItem } from '@material-ui/core'

import { plant } from '../../actions'
import CustomTable from '../Table/CustomTable'

class Plant extends Component {
 
state = {
      page: 0,
      rowsPerPage: 10,
      query: '',
      filterPlantGenus: '',
      filterPlantGenera: [],
      fields: [
        {
          id: 'commonName',
          label: 'Latin Name'
        },
        {
          id: 'latinName',
          label: 'Common Name'
        },
        {
          id: 'plantDescription',
          label: 'Plant Description'
        },
      ],
    }
  
  componentDidMount() {
    const query = qs.parse(this.props.location.search)
    this.setState(
      {
        query: query.searchText || '',
        page: query.page || 0
      },
      () => {
        this._getPlant()
      }
    )
  }
  _getPlant() {
    const searchText = this.state.query || ''
    const page = this.state.page || 0
    this.props.dispatch(
      plant.count(searchText, this.state.filterPlantGenus)
    )
    this.props.dispatch(
      plant.fetch(
        page,
        this.state.rowsPerPage,
        searchText,
        this.state.filterPlantGenus
      )
    )
    const search = qs.stringify({
      searchText,
      page
    })
    this.props.history.push({ search })
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getPlant()
    })
  }
  handleSearch = debounce(query => {
    this.setState(
      {
        query
      },
      () => {
        this._getPlant()
      }
    )
  }, 1000)
  handleSelectChange = event => {
    this.setState({
      filterPlantGenus: event.target.value
    })
    this.handleSearch(this.state.query)
  }
  handleMerge = (primary, selected) => {
    this.props.dispatch(plant.mergePlants(primary, selected))
  }
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getPlant()
    })
  }
  handleDeleteAction = id => {
    this.props.dispatch(plant.delete(id))
  }
  hideAlert() {
    this.props.dispatch(plant.hideAlert())
    this.props.record
      ? window.location.reload()
      : this.props.history.push('/plant')
  }
  render() {
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push('/plant/add')}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title={'Plant'}
          total={this.props.total}
          history={this.props.history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={Number(this.state.page)}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          extraSearchElements={
            <FormControl>
              <Select
                value={this.state.filterPlantGenus}
                onChange={this.handleSelectChange}
              >
                <MenuItem value="none">No genus</MenuItem>
                {this.state.filterPlantGenera.map(genus => {
                  return (
                    <MenuItem value={genus.id} key={genus.id}>
                      {genus.title}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          }
          handleDelete={this.handleDeleteAction}
          handleMerge={this.handleMerge}
          mergeSuccess={this.props.mergingPlantsSuccess}
          mergeFailure={this.props.mergingPlantsFailure}
          merging={this.mergingPlants}
          data={this.props.list}
          editPath="/plant/edit/"
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: '/plant/edit/',
              has_id: true,
              color: 'primary'
            },
            {
              label: 'merge',
              icon: <MergeTypeIcon />,
              path: '/plant/merge/',
              has_id: true,
              color: 'primary'
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary'
            }
          ]}
        />
      </div>
    )
  }
}

Plant.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number,
  list: PropTypes.array,
  record: PropTypes.object,
  searchText: PropTypes.string,
  errorMessage: PropTypes.string,
  deleted: PropTypes.bool,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  mergingPlantsSuccess: PropTypes.bool,
  mergingPlantsFailure: PropTypes.bool
}

const mapStateToProps = state => ({
  total: state.plant.total,
  list: state.plant.list,
  deleted: state.plant.deleted,
  errorMessage: state.plant.errorMessage,
  mergingPlants: state.plant.mergingPlants,
  mergingPlantsSuccess: state.plant.mergingPlantsSuccess,
  mergingPlantsFailure: state.plant.mergingPlantsFailure
})

export default connect(mapStateToProps)(Plant)
