import {
  COUNT_PRODUCT_FAILED,
  COUNT_PRODUCT_SUCCEED,
  COUNT_PRODUCT_PROCESSING,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_SUCCEED,
  FETCH_PRODUCT_PROCESSING,
  GET_PRODUCT_FAILED,
  GET_PRODUCT_SUCCEED,
  GET_PRODUCT_PROCESSING,
  FETCH_PRODUCT_REGIONS_FAILED,
  FETCH_PRODUCT_REGIONS_SUCCEED,
  FETCH_PRODUCT_REGIONS_PROCESSING,
  SAVE_PRODUCT_FAILED,
  SAVE_PRODUCT_SUCCEED,
  SAVE_PRODUCT_PROCESSING,
  DELETE_PRODUCT_FAILED,
  DELETE_PRODUCT_SUCCEED,
  DELETE_PRODUCT_PROCESSING,
} from '../../constants'
import Parse from '../parse'
let Products = new Parse.Object.extend('Products')
const useMasterKey = true

function getProductQuery(searchText) {
  let query = new Parse.Query(Products)
  searchText = searchText || ''
  if (searchText !== '') {
    const productNameQuery = new Parse.Query(Products)
    productNameQuery.matches('productName', searchText.trim(), 'i')
    const productDescriptionQuery = new Parse.Query(Products)
    productDescriptionQuery.matches(
      'productDescription',
      searchText.trim(),
      'i'
    )
  }
  return query
}

export const product = {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_PRODUCT_PROCESSING,
      })
      let query = new Parse.Query(Products)
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('productName', searchText.trim(), 'i')
      }
      try {
        const total = await query.count({ useMasterKey })
        dispatch({
          type: COUNT_PRODUCT_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        dispatch({
          type: COUNT_PRODUCT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  fetch(page, rowsPerPage, searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_PRODUCT_PROCESSING,
      })
      const query = getProductQuery(searchText)
      try {
        const products = await query
          .descending('updatedAt')
          .limit(rowsPerPage)
          .skip(page * rowsPerPage)
          .find()
        let list = products.map(product => {
          return {
            [product.id]: {
              productImage:
                product.get('productImage') &&
                product.get('productImage').url(),
              productName: product.get('productName'),
              productDescription: product.get('productDescription'),
            },
          }
        })
        return dispatch({
          type: FETCH_PRODUCT_SUCCEED,
          payload: { list },
        })
      } catch (error) {
        dispatch({
          type: FETCH_PRODUCT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_PRODUCT_PROCESSING,
      })
      let query = new Parse.Query(Products)
      try {
        const product = await query
          .include('categoryType')
          .include('Regions')
          .get(id)
        const regions = product.get('Regions')
        const record = {
          object_id: id,
          productImage: {
            changed: false,
            uri: product.get('productImage').url(),
            imageData: null,
          },
          productName: product.get('productName'),
          productDescription: product.get('productDescription'),
          Regions: regions.map(r => ({
            value: r ? r.id : '',
            label: r ? r.get('regionName') : '',
          })),
        }
        dispatch({
          type: GET_PRODUCT_SUCCEED,
          payload: {
            record,
          },
        })
      } catch (error) {
        dispatch({
          type: GET_PRODUCT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getRegions(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_PRODUCT_REGIONS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Region'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('productName', searchText);
        query.matches('regionName', searchText.trim(), 'i')
      }
      query.limit(10000)
      try {
        const results = await query.find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('regionName'),
          })
        }
        dispatch({
          type: FETCH_PRODUCT_REGIONS_SUCCEED,
          payload: {
            regions: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_PRODUCT_REGIONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_PRODUCT_PROCESSING,
      })
      let product = {}
      const productQuery = new Parse.Query(Products)
      if (
        values.object_id !== undefined &&
        values.object_id !== null &&
        values.object_id !== ''
      ) {
        try {
          product = await productQuery.get(values.object_id)
        } catch (err) {
          return dispatch({
            type: SAVE_PRODUCT_FAILED,
            payload: {
              error: `Error: ${err.code} ${err.message}`,
            },
          })
        }
      } else {
        product = new Products()
      }
      if (
        values.productImage &&
        values.productImage.changed &&
        values.productImage.imageData
      ) {
        let productImage = new Parse.File('productImage', {
          base64: values.productImage.imageData,
        })
        try {
          await productImage.save()
          product.set('productImage', productImage)
        } catch (err) {
          return dispatch({
            type: SAVE_PRODUCT_FAILED,
            payload: {
              error: `Error: ${err.code} ${err.message}`,
            },
          })
        }
      }
      if (values.Regions && values.Regions.length) {
        let regionQuery = new Parse.Query(Parse.Object.extend('Region'))
        let regions = await regionQuery
          .containedIn('objectId', values.Regions.map(r => r.value))
          .find()
        product.set('Regions', regions.map(r => r.toPointer()))
      }
      try {
        await product.save(
          {
            productName: values.productName,
            productDescription: values.productDescription,
          },
          { useMasterKey }
        )
        return dispatch({
          type: SAVE_PRODUCT_SUCCEED,
        })
      } catch (err) {
        return dispatch({
          type: SAVE_PRODUCT_FAILED,
          payload: {
            error: `Error: ${err.code} ${err.message}`,
          },
        })
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_PRODUCT_PROCESSING,
      })
      let query = new Parse.Query(Products)
      try {
        const products = await query.containedIn('objectId', ids).find()
        await Parse.Object.destroyAll(products)
        dispatch({
          type: DELETE_PRODUCT_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: DELETE_PRODUCT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
}
