import {
  COUNT_SNAPP_FAILED,
  COUNT_SNAPP_SUCCEED,
  COUNT_SNAPP_PROCESSING,
  FETCH_SNAPP_FAILED,
  FETCH_SNAPP_SUCCEED,
  FETCH_SNAPP_PROCESSING,
  GET_SNAPP_FAILED,
  GET_SNAPP_SUCCEED,
  GET_SNAPP_PROCESSING,
  FETCH_SNAPP_USERS_FAILED,
  FETCH_SNAPP_USERS_SUCCEED,
  FETCH_SNAPP_USERS_PROCESSING,
  SAVE_SNAPP_FAILED,
  SAVE_SNAPP_SUCCEED,
  SAVE_SNAPP_PROCESSING,
  DELETE_SNAPP_FAILED,
  DELETE_SNAPP_SUCCEED,
  DELETE_SNAPP_PROCESSING,
  SELECT_PLANT,
  SELECT_USER,
  HIDE_ALERT,
  ASSIGN_EXPERT_FAILED,
  ASSIGN_EXPERT_PROCESSING,
  ASSIGN_EXPERT_SUCCEED,
  ASSIGN_PLANT_FAILED,
  ASSIGN_PLANT_PROCESSING,
  ASSIGN_PLANT_SUCCEED,
} from '../constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorMessage: '',
  list: [],
  total: 0,
  users: [],
  record: {
    snappType: '',
    regionName: '',
  },
  selectedPlant: { value: '', label: '' },
  selectedUser: { value: '', label: '' },
  assigningPlant: false,
  assignedPlant: false,
  assigningExpert: false,
  assignedExpert: false,
}

const snapp = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SNAPP_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
        list: [],
      }
    case FETCH_SNAPP_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        assigningExpert: false,
        assignedExpert: false,
        assignedPlant: false,
        assigningPlant: false,
        errorMessage: '',
      }
    case FETCH_SNAPP_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: '',
        list: action.payload.list,
        assigningExpert: false,
        assignedExpert: false,
        assignedPlant: false,
        assigningPlant: false,
      }
    case GET_SNAPP_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
        record: initialState.record,
      }
    case GET_SNAPP_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        record: initialState.record,
        assigningExpert: false,
        assignedExpert: false,
        assignedPlant: false,
        assigningPlant: false,
        errorMessage: '',
      }
    case GET_SNAPP_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: '',
        record: action.payload.record,
        selectedPlant: action.payload.selectedPlant,
        selectedUser: action.payload.selectedUser,
        assigningExpert: false,
        assignedExpert: false,
        assignedPlant: false,
        assigningPlant: false,
      }
    case COUNT_SNAPP_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_SNAPP_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_SNAPP_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: '',
        total: action.payload.total,
      }
    case FETCH_SNAPP_USERS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_SNAPP_USERS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_SNAPP_USERS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: '',
        users: action.payload.users,
      }
    case SAVE_SNAPP_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
        errorMessage: action.payload.error,
      }
    case SAVE_SNAPP_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_SNAPP_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
        errorMessage: '',
      }
    case DELETE_SNAPP_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_SNAPP_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_SNAPP_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
        errorMessage: '',
      }
    case SELECT_PLANT:
      return {
        ...state,
        selectedPlant: action.payload.plant,
      }
    case SELECT_USER:
      return {
        ...state,
        selectedUser: action.payload.user,
      }
    case HIDE_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        assignedPlant: false,
        assignedExpert: false,
        errorMessage: '',
      }
    case ASSIGN_EXPERT_FAILED:
      return {
        ...state,
        assigningExpert: false,
        errorMessage: action.payload.error,
      }
    case ASSIGN_EXPERT_PROCESSING:
      return {
        ...state,
        assigningExpert: true,
        assignedExpert: false,
      }
    case ASSIGN_EXPERT_SUCCEED:
      return {
        ...state,
        assigningExpert: false,
        assignedExpert: true,
        errorMessage: '',
      }
    case ASSIGN_PLANT_FAILED:
      return {
        ...state,
        assigningPlant: false,
        errorMessage: action.payload.error,
      }
    case ASSIGN_PLANT_PROCESSING:
      return {
        ...state,
        assigningPlant: true,
        assignedPlant: false,
      }
    case ASSIGN_PLANT_SUCCEED:
      return {
        ...state,
        assigningPlant: false,
        assignedPlant: true,
        errorMessage: '',
      }
    default:
      return state
  }
}

export default snapp
