import {
  COUNT_UNMATCHED_BARCODE_FAILED,
  COUNT_UNMATCHED_BARCODE_SUCCEED,
  COUNT_UNMATCHED_BARCODE_PROCESSING,
  FETCH_UNMATCHED_BARCODE_FAILED,
  FETCH_UNMATCHED_BARCODE_SUCCEED,
  FETCH_UNMATCHED_BARCODE_PROCESSING,
  GET_UNMATCHED_BARCODE_FAILED,
  GET_UNMATCHED_BARCODE_SUCCEED,
  GET_UNMATCHED_BARCODE_PROCESSING,
  FETCH_UNMATCHED_BARCODE_BARCODES_FAILED,
  FETCH_UNMATCHED_BARCODE_BARCODES_SUCCEED,
  FETCH_UNMATCHED_BARCODE_BARCODES_PROCESSING,
  SAVE_UNMATCHED_BARCODE_FAILED,
  SAVE_UNMATCHED_BARCODE_SUCCEED,
  SAVE_UNMATCHED_BARCODE_PROCESSING,
  SET_UNMATCHED_BARCODE_PAGE,
  DELETE_UNMATCHED_BARCODE_FAILED,
  DELETE_UNMATCHED_BARCODE_SUCCEED,
  DELETE_UNMATCHED_BARCODE_PROCESSING,
  SELECT_PLANT,
  HIDE_ALERT,
  TOGGLE_ADDING_NEW_PLANT,
} from '../constants/unmatchedBarcode.constants'

const initialState = {
  currentPage: 0,
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorMessage: '',
  list: [],
  total: 0,
  selectedPlant: null,
  retailersGroups: [],
  addingNewPlant: false,
  record: {
    latinName: '',
    plantDescription: 'foo',
    genus: {value: '', label: ''}
  },
}

const unmatchedBarcode = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UNMATCHED_BARCODE_FAILED:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload.error,
      }
    case FETCH_UNMATCHED_BARCODE_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: ''
      }
    case FETCH_UNMATCHED_BARCODE_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        errorMessage: ''
      }
    case GET_UNMATCHED_BARCODE_FAILED:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload.error,
      }
    case GET_UNMATCHED_BARCODE_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case GET_UNMATCHED_BARCODE_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        selectedPlant: action.payload.selectedPlant,
        errorMessage: '',
      }
    case COUNT_UNMATCHED_BARCODE_FAILED:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload.error,
      }
    case COUNT_UNMATCHED_BARCODE_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_UNMATCHED_BARCODE_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_UNMATCHED_BARCODE_BARCODES_FAILED:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload.error,
      }
    case FETCH_UNMATCHED_BARCODE_BARCODES_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_UNMATCHED_BARCODE_BARCODES_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        unmatchedBarcodes: action.payload.unmatchedBarcodes,
      }
    case SAVE_UNMATCHED_BARCODE_FAILED:
      return {
        ...state,
        saving: false,
        errorMessage: action.payload.error,
      }
    case SAVE_UNMATCHED_BARCODE_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_UNMATCHED_BARCODE_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case SET_UNMATCHED_BARCODE_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }
    case DELETE_UNMATCHED_BARCODE_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_UNMATCHED_BARCODE_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_UNMATCHED_BARCODE_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case SELECT_PLANT:
      return {
        ...state,
        selectedPlant: action.payload.plant,
      }
    case HIDE_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        imported: false,
        errorMessage: '',
      }
    case TOGGLE_ADDING_NEW_PLANT:
      return {
        ...state,
        addingNewPlant: action.payload,
      }
    default:
      return state
  }
}

export default unmatchedBarcode
