import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes as reduxFormPropTypes } from 'redux-form'
import {
  Button,
  SnackbarContent,
  Typography,
  withStyles,
  Grid,
  TextField,
} from '@material-ui/core'
import SweetAlert from 'sweetalert2-react'
import AsyncSelect from 'react-select/lib/Async'
import Loader from 'react-loader'
import debounce from 'p-debounce'

import RegularCard from '../Cards/RegularCard.js'
import { plant as plantAPI } from '../../api/modules/plant.api'
import { genus as genusAPI } from '../../api/modules/genus.api'
import {
  barcode,
  unmatchedBarcode as unmatchedBarcodeActions,
} from '../../actions'
import CustomTextField from '../Input/CustomTextField.js'

export const fields = ['object_id', 'file', 'retailersGroup']

const validate = values => {
  const errors = {}
  return errors
}

const onSubmit = async(values, dispatch, props) => {
  await props.save({
    id: props.record.id,
    record: props.record,
    barcode: values,
    addingNewPlant: props.addingNewPlant,
  })
  await props.count()
  await props.getNext(props.currentPage)
}

const overriddenStyles = { 
  option: (provided, state) => (
    { 
      color: state.isFocused ? '#ffffff' : '#434343', 
      fontWeight: state.isFocused ? 'bold' : 'normal',
      backgroundColor: state.isFocused ? '#434343' : '#ffffff'
    }
  )
}

class MatchBarcode extends Component {
  state = {
    plantSearchInput: '',
  }

  static propTypes = {
    ...reduxFormPropTypes,
    retailersGroups: PropTypes.array.isRequired,
    list: PropTypes.array,
    record: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    fetching: PropTypes.bool,
    total: PropTypes.number.isRequired,
    getRetailersGroups: PropTypes.func.isRequired,
    count: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    addingNewPlant: PropTypes.bool.isRequired,
    toggleAddingNewPlant: PropTypes.func.isRequired,
    setUnmatchedBarcodePage: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
  }

  async componentDidMount() {
    await this.props.toggleAddingNewPlant(false)
    await this.props.count()
    let index = 0
    if (this.props.match.params.index) {
      index = Number(this.props.match.params.index)
      await this.props.setUnmatchedBarcodePage(index)
    }
    await this.props.getNext(index)
  }

  skip = async page => {
    await this.props.setUnmatchedBarcodePage(page)
    await this.props.getNext(page)
  }

  changePage = async value => {
    const index = Number(value) - 1
    await this.props.setUnmatchedBarcodePage(index)
    await debounce(async() => {
      if (index >= 0 && index < this.props.total) {
        await this.props.getNext(index)
      }
    }, 1000)()
  }

  getPlants = query => plantAPI.select(query)

  getGenera = query => genusAPI.select(query)

  focusOnPlantSearch = pristine => {
    if (pristine) {
      this.setState({
        plantSearchInput: this.props.record.latinName
          .split(' ')
          .slice(0, 2)
          .join(' '),
      })
    }
  }

  render() {
    const {
      record,
      handleSubmit,
      imported,
      errorMessage,
      total,
      classes,
      pristine,
      fetching,
      invalid,
      saving,
      addingNewPlant,
      toggleAddingNewPlant,
      currentPage,
    } = this.props
    const title = `Match (${currentPage + 1} out of ${total} remaining)`
    return (
      <>
        <form onSubmit={handleSubmit}>
          <RegularCard
            cardTitle={title}
            content={
              <Loader loaded={!fetching && !saving}>
                <Grid container direction="column" spacing={16}>
                  <Grid item>
                    <Typography
                      variant="h3"
                      className={classes.details}
                    >
                      Barcode: <b>{record.barcode}</b>
                    </Typography>
                    <Typography
                      variant="h3"
                      className={classes.details}
                    >
                      Imported name: <b>{record.latinName}</b>
                    </Typography>
                    <Typography
                      variant="h3"
                      className={classes.details}
                    >
                      Imported plant description:{' '}
                      <b>{record.plantDescription}</b>
                    </Typography>
                  </Grid>
                  {!addingNewPlant && (
                    <Grid item>

                      <Field
                        name="plant"
                        label="Search for plants"
                        component={({ input }) => (
                          <>
                            <Typography
                              variant="h3"
                              className={classes.details}
                            >
                              Plant: <b>{input.value.label}</b>
                            </Typography>

                            <AsyncSelect
                              ref={this.plantSearchRef}
                              defaultInputValue={record.latinName
                                .split(' ')
                                .slice(0, 2)
                                .join(' ')}
                              onChange={input.onChange}
                              defaultMenuIsOpen={true}
                              placeholder="Search for plant"
                              loadOptions={this.getPlants}
                              cacheOptions
                              defaultOptions
                              autoFocus
                              styles={overriddenStyles}
                            />
                          </>
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => toggleAddingNewPlant(!addingNewPlant)}
                    >
                      {!addingNewPlant ? 'Add new plant' : 'Add existing plant'}
                    </Button>
                  </Grid>
                  {addingNewPlant && (
                    <>
                      <Grid item>
                        <Field
                          name="latinName"
                          label="Common name"
                          component={CustomTextField}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="commonName"
                          label="Latin name"
                          component={CustomTextField}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="genus"
                          label="Search for genera"
                          component={props => (
                            <>
                              <Typography
                                variant="h3"
                                className={classes.details}
                              >
                                Genus: <b>{props.input.value.label}</b>
                              </Typography>
                              <AsyncSelect
                                defaultValue={props.input.value.label}
                                onChange={props.input.onChange}
                                placeholder="Search for genera"
                                loadOptions={this.getGenera}
                              />
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="plantDescription"
                          label="Plant description"
                          component={CustomTextField}
                          multiline={true}
                          rows={3}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="bloomSeason"
                          label="Bloom season"
                          component={CustomTextField}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="lightRecommendation"
                          label="Light recommendation"
                          component={CustomTextField}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="heightAndWidth"
                          label="Height and width"
                          component={CustomTextField}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="pruningNeeds"
                          label="Pruning needs"
                          component={CustomTextField}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="wateringRecommendation"
                          component={CustomTextField}
                          label="Watering recommendation"
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="buyURL"
                          component={CustomTextField}
                          label="Buy URL"
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="timeOfYearToPlant"
                          component={CustomTextField}
                          label="Time of year to plant"
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="soilTypeRecommendation"
                          component={CustomTextField}
                          label="Soil type recommendation"
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="hardiness"
                          component={CustomTextField}
                          label="Hardiness"
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name="usdaUkPlantingZones"
                          component={CustomTextField}
                          label="USDA UK planting zones"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Loader>
            }
            footer={
              <>
                <TextField
                  id="current-page"
                  label="Page"
                  value={currentPage + 1}
                  onChange={e => this.changePage(e.target.value)}
                  type="number"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  style={{ marginRight: 10 }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={pristine || fetching || invalid || saving}
                  style={{ marginRight: 10 }}
                >
                  {`Save & next`}
                </Button>
                <Button
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => this.skip(currentPage - 1)}
                  disabled={currentPage <= 0}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => this.skip(currentPage + 1)}
                  disabled={currentPage >= total - 1}
                >
                  Next
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.history.push('/barcode')}
                >
                  Finish
                </Button>
              </>
            }
          />
        </form>
        {errorMessage && <SnackbarContent message={errorMessage} />}
        <SweetAlert
          type={errorMessage ? 'error' : 'success'}
          show={imported || Boolean(errorMessage)}
          title={errorMessage ? 'Error' : 'Notice'}
          text={
            errorMessage
              ? errorMessage
              : 'File was uploaded. When all barcodes will be saved, you will get an confirmation email.'
          }
          onConfirm={() => this.props.dispatch(barcode.hideAlert())}
        />
      </>
    )
  }
}

const mapDispatchToProps = {
  ...unmatchedBarcodeActions,
  getRetailersGroups: barcode.getRetailersGroups,
}

const mapStateToProps = ({ barcode, unmatchedBarcode, plant }) => ({
  ...unmatchedBarcode,
  retailersGroups: barcode.retailersGroups,
  namePlants: plant.namePlants,
  importing: barcode.importing,
  imported: barcode.imported,
  errorMessage: barcode.errorMessage,
  fields,
  initialValues: {
    latinName: unmatchedBarcode.record.latinName,
    plantDescription: unmatchedBarcode.record.plantDescription,
    genus: unmatchedBarcode.record.genus,
  },
})

const style = theme => ({
  details: {
    marginBottom: theme.spacing(1),
  },
})

export default withStyles(style)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'match-barcode',
      validate,
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(MatchBarcode)
  )
)
