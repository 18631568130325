import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth.reducers'
import barcode from './barcode.reducers'
import unmatchedBarcode from './unmatchedBarcode.reducers'
import careIcon from './careIcon.reducers'
import category from './category.reducers'
import categoryCareInfo from './categoryCareInfo.reducers'
import expert from './expert.reducers'
import genus from './genus.reducers'
import genusCareInfo from './genusCareInfo.reducers'
import marketing from './marketing.reducers'
import master from './master.reducers'
import plant from './plant.reducers'
import product from './product.reducers'
import region from './region.reducers'
import report from './report.reducers'
import retailer from './retailer.reducers'
import retailersGroup from './retailersGroup.reducers'
import snapp from './snapp.reducers'
import user from './user.reducers'

const rootReducer = combineReducers({
  auth,
  barcode,
  careIcon,
  category,
  categoryCareInfo,
  expert,
  genus,
  genusCareInfo,
  marketing,
  master,
  plant,
  product,
  region,
  report,
  retailer,
  retailersGroup,
  snapp,
  unmatchedBarcode,
  user,
  form: formReducer,
})

export default rootReducer
