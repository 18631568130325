import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, reduxForm } from 'redux-form'
import { TextField, Button, CardActions, Typography, Divider } from '@material-ui/core'
import Select from 'react-select'

import SweetAlert from 'sweetalert2-react'

import RegularCard from "../Cards/RegularCard.js"
import { expert } from "../../actions"

// @TODO: Add loading spinner (see CategoryForm)

export const fields = ['object_id', 'user_id', 'firstName', 'lastName', 'email', 'regionName', 'job', 'phoneNumber', 'accountNumber', 'accountName', 'paypalAccount']
const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
        <TextField
            helperText={touched ? error : ""}
            error={(touched ? ((error === undefined) ? false : true) : false)}
            {...inputProps}
            {...props}
            fullWidth
        />
    )
class ExpertsForm extends Component {

    // constructor(props) {
    //     super(props);        
    // }
    handleChangeImage(e) {
    }
    submit(values) {
        values.object_id = this.props.initialValues.object_id
        values.user_id = this.props.initialValues.user_id
        values.accountNumber = parseInt(values.accountNumber, 0)
        this.props.dispatch(expert.save(values))
    }
    componentDidMount() {
        this.props.dispatch(expert.getRegions())
        if (this.props.match.params.id) {
            this.props.dispatch(expert.get(this.props.match.params.id))
        }
    }
    hideAlert(event) {
        this.props.dispatch(expert.hideAlert())
        this.props.record ? window.location.reload() : this.props.history.push("/expert")
    }
    render() {
        const { record, regions, handleSubmit, saved, errorMessage } = this.props
        const title = (this.props.match.params.id ? "Edit " : "Add new") + (record !== null && record.get("user") ? record.get("user").get("firstName") + " " + record.get("user").get("lastName") : "")
        return (
            <div>
                <RegularCard
                    cardTitle={title}
                    content={
                        <form onSubmit={handleSubmit(this.submit.bind(this))}>
                            <Typography variant="headline" style={{ textAlign: "center" }} gutterBottom>
                                Personal Details
                            </Typography>
                            <Divider style={{ marginBottom: "20px" }} />
                            <div>
                                <Field
                                    name="firstName"
                                    component={renderInput}
                                    label="First Name"
                                />
                            </div>
                            <div>
                                <Field
                                    name="lastName"
                                    component={renderInput}
                                    label="Last Name"
                                />
                            </div>
                            {this.props.match.params.id ? (null
                            ) : (
                                    <div>
                                        <div>
                                            <Field
                                                name="email"
                                                component={renderInput}
                                                label="Email"
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                name="password"
                                                component={renderInput}
                                                type="password"
                                                label="Password"
                                            />
                                        </div>
                                    </div>
                                )}
                            <div>
                                <label>Regions</label>
                                <Field name="expertRegions"
                                    label="Regions"
                                    component={props =>
                                        <Select
                                            value={props.input.value}
                                            onChange={props.input.onChange}
                                            onBlur={() => {
                                                props.input.onBlur(props.input.value.map(option => option.value))
                                            }}
                                            options={regions}
                                            placeholder="Select regions"
                                            multi={true}
                                        />
                                    }
                                />
                            </div>
                            <div>
                                <Field
                                    name="job"
                                    component={renderInput}
                                    label="Job"
                                />
                            </div>
                            <div>
                                <Field
                                    name="phoneNumber"
                                    component={renderInput}
                                    label="Phone Number"
                                />
                            </div>

                            <Typography variant="headline" style={{ textAlign: "center", marginTop: 10 }} gutterBottom>
                                Account Details
                            </Typography>
                            <Divider style={{ marginBottom: "20px" }} />
                            <div>
                                <Field
                                    name="accountName"
                                    component={renderInput}
                                    label="Account Name"
                                />
                            </div>
                            <div>
                                <Field
                                    name="accountNumber"
                                    component={renderInput}
                                    label="Account Number"
                                />
                            </div>
                            <div>
                                <Field
                                    name="paypalAccount"
                                    component={renderInput}
                                    label="Paypal Account"
                                />
                            </div>



                            <CardActions>
                                <Button type="submit" variant="contained" color="primary" disabled={this.props.fetching}>
                                    Submit
                                </Button>
                                <Button variant="contained" color="secondary" onClick={() => this.props.history.push("/expert")}>
                                    Back to list
                                </Button>
                            </CardActions>
                        </form>
                    }
                    footer={""}
                />
                <SweetAlert
                    type={errorMessage ? 'error' : 'success'}
                    show={saved || (errorMessage ? true : false)}
                    title={errorMessage ? "Error" : "Notice"}
                    text={errorMessage ? errorMessage : "Saved"}
                    onConfirm={this.hideAlert.bind(this)}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    record: state.expert.record,
    regions: state.expert.regions,
    fetching: state.expert.fetching,
    fetched: state.expert.fetched,
    saving: state.expert.saving,
    saved: state.expert.saved,
    errorMessage: state.expert.errorMessage,
    fields,
    initialValues: {
        object_id: state.expert.record ? state.expert.record.id : null,
        user_id: (state.expert.record && state.expert.record.get("user")) ? state.expert.record.get("user").id : null,
        firstName: (state.expert.record && state.expert.record.get("user")) ? state.expert.record.get("user").get("firstName") : "",
        lastName: (state.expert.record && state.expert.record.get("user")) ? state.expert.record.get("user").get("lastName") : "",
        email: (state.expert.record && state.expert.record.get("user")) ? state.expert.record.get("user").get("email") : "",
        password: (state.expert.record && state.expert.record.get("user")) ? state.expert.record.get("user").get("password") : "",
        phoneNumber: state.expert.record ? state.expert.record.get("phoneNumber") : "",
        job: state.expert.record ? state.expert.record.get("job") : "",
        accountNumber: state.expert.record ? state.expert.record.get("accountNumber") : "",
        accountName: state.expert.record ? state.expert.record.get("accountName") : "",
        paypalAccount: state.expert.record ? state.expert.record.get("paypalAccount") : "",
        expertRegions: (state.expert.record && state.expert.record.get("expertRegions") && state.expert.record.get("expertRegions")[0] !== null) ? state.expert.record.get("expertRegions").map((region) => region.id) : [],
    }
})

export default connect(mapStateToProps)(reduxForm({
    // a unique name for the form
    form: 'expert',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(ExpertsForm))
