import {
  category as categoryAPI
} from '../api'
import {
  HIDE_ALERT
} from '../constants'
export const category = {
  list(page, rowsPerPage, query) {
    return (dispatch) => {
      dispatch(categoryAPI.list(page, rowsPerPage, query))
    }
  },
  get(id) {
    return (dispatch) => {
      dispatch(categoryAPI.get(id))
    }
  },
  getCategories() {
    return (dispatch) => {
      dispatch(categoryAPI.getCategories())
    }
  },
  getProducts(query) {
    return (dispatch) => {
      dispatch(categoryAPI.getProducts(query))
    }
  },
  count(query) {
    return (dispatch) => {
      dispatch(categoryAPI.count(query))
    }
  },
  save(values) {
    return (dispatch) => {
      dispatch(categoryAPI.save(values))
    }
  },
  delete(id) {
    return (dispatch) => {
      dispatch(categoryAPI.delete(id))
    }
  },
  hideAlert() {
    return (dispatch) => {
      dispatch({
        type: HIDE_ALERT
      })
    }
  }
}