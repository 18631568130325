import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  ImportExport,
  FormatListNumbered,
} from '@material-ui/icons'
import { Button } from '@material-ui/core'
import debounce from 'p-debounce'

import {
  barcode as barcodeActions,
  unmatchedBarcode as unmatchedBarcodeActions,
} from '../../actions'
import CustomTable from '../Table/CustomTable'

class BarcodeList extends Component {
  static propTypes = {
    count: PropTypes.func.isRequired,
    countUnmatchedBarcodes: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    deleted: PropTypes.bool,
    errorMessage: PropTypes.string,
    fetch: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    unmatchedBarcodesTotal: PropTypes.number.isRequired,
  }

  state = {
    page: 0,
    rowsPerPage: 10,
    fields: [
      {
        id: 'barcode',
        label: 'Barcode',
      },
      {
        id: 'plantCommonName',
        label: 'Latin Name',
      },
      {
        id: 'plantLatinName',
        label: 'Common Name',
      },
      {
        id: 'retailersGroups',
        label: 'Retailer Groups',
      },
    ],
  }

  async componentDidMount() {
    await this.props.count()
    await this._getBarcode()
    await this.props.countUnmatchedBarcodes()
  }
  async _getBarcode() {
    await this.props.fetch(this.state.page, this.state.rowsPerPage)
  }
  handleChangePage = page => {
    this.setState({ page: page }, async() => {
      await this._getBarcode()
    })
  }
  handleSearch = debounce(async query => {
    await this.props.count(query)
    await this.props.fetch(this.state.page, this.state.rowsPerPage, query)
  }, 1000)
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, async() => {
      await that._getBarcode()
    })
  }
  handleDeleteAction = async id => {
    await this.props.delete(id)
  }
  hideAlert() {
    this.props.hideAlert()
  }
  render() {
    const { unmatchedBarcodesTotal } = this.props
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          {unmatchedBarcodesTotal > 0 && (
            <Button
              variant="contained"
              color="secondary"
              style={{ marginRight: 10 }}
              onClick={() => this.props.history.push('/barcode/match')}
            >
              <FormatListNumbered /> Match {unmatchedBarcodesTotal} barcode
              {unmatchedBarcodesTotal > 1 ? 's' : ''}
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            style={{ marginRight: 10 }}
            onClick={() => this.props.history.push('/barcode/import')}
          >
            <ImportExport /> Import
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push('/barcode/add')}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title={'Barcode'}
          total={this.props.total}
          history={this.props.history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={this.props.list}
          editPath="/barcode/edit/"
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: '/barcode/edit/',
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ barcode, unmatchedBarcode }) => ({
  total: barcode.total,
  list: barcode.list,
  deleted: barcode.deleted,
  errorMessage: barcode.errorMessage,
  unmatchedBarcodesTotal: unmatchedBarcode.total,
})

const mapDispatchToProps = {
  ...barcodeActions,
  countUnmatchedBarcodes: unmatchedBarcodeActions.count,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BarcodeList)
