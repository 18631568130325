import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  Divider,
  CircularProgress,
  Fade,
  withStyles,
} from '@material-ui/core'
import Select from 'react-select'
import Async from 'react-select/lib/Async'
import Loader from 'react-loader'
import moment from 'moment'
import Image from 'react-image'

import { plant as plantAPI } from '../../api'
import { snapp as snappActions } from '../../actions/snapp.actions'
import CustomSnackbar from 'components/CustomSnackbar'

// @TODO: Add loading spinner (see CategoryForm)

const reactSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    color: 'black',
  }),
}

class SnappForm extends Component {
  state = {
    plantComment: '',
    expertComment: '',
  }

  static propTypes = {
    selectPlant: PropTypes.func.isRequired,
    selectUser: PropTypes.func.isRequired,
    selectedPlant: PropTypes.object,
    selectedUser: PropTypes.object,
    getUsers: PropTypes.func.isRequired,
    assignPlant: PropTypes.func.isRequired,
    assignExpert: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    errorMessage: PropTypes.string,
    assignedPlant: PropTypes.bool,
    assignedExpert: PropTypes.bool,
    assigningExpert: PropTypes.bool,
    assigningPlant: PropTypes.bool,
  }

  getPlants(query) {
    if (!query) {
      return false
    }
    return plantAPI.select(query)
  }
  handlePlantChange = async value => {
    await this.props.selectPlant(value)
    this.setState({ plantDescription: value ? value.plantDescription : null })
  }
  handleUserChange = async value => {
    await this.props.selectUser(value)
  }
  handlePlantCommentChange = event => {
    this.setState({ plantComment: event.target.value })
  }
  handleExpertCommentChange = event => {
    this.setState({ expertComment: event.target.value })
  }
  async componentDidMount() {
    if (this.props.match.params.id) {
      await this.props.get(this.props.match.params.id)
    }
    await this.props.getUsers()
  }
  assignPlant = async () => {
    if (this.props.record) {
      if (this.props.selectedPlant) {
        await this.props.assignPlant(
          this.props.record.id,
          this.props.selectedPlant.id,
          this.state.plantComment
        )
      } else {
        // console.log("Error: No plant selected")
      }
    } else {
      // console.log("Error: No record found")
    }
  }
  assignExpert = async () => {
    if (this.props.record) {
      if (this.props.selectedUser) {
        try {
          await this.props.assignExpert(
            this.props.record.id,
            this.props.selectedUser.value,
            this.state.expertComment
          )
        } catch (err) {}
      }
    }
  }
  render() {
    const {
      record,
      errorMessage,
      classes,
      selectedPlant,
      users,
      selectedUser,
      assignedPlant,
      assignedExpert,
      assigningExpert,
      assigningPlant,
    } = this.props
    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') + record.snappType
    return (
      <div className={classes.root}>
        <CustomSnackbar
          variant="error"
          open={Boolean(errorMessage)}
          message={errorMessage}
          handleClose={() => this.handleCloseError()}
        />
        <CustomSnackbar
          variant="success"
          open={assignedPlant}
          message="Assigned Plant"
        />
        <CustomSnackbar
          variant="success"
          open={assignedExpert}
          message="Assigned Expert"
        />
        <Fade in={this.props.fetching}>
          <div
            className={classes.overlay}
            style={{ zIndex: this.props.fetching ? 10 : 0 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <div>
                {record ? (
                  <div style={{ fontSize: '14px', marginBottom: '10px' }}>
                    <strong>User Region </strong>
                    <span>{record.regionName} </span>/<strong> Posted </strong>
                    <span>{moment(record.createdAt).fromNow()}</span>
                  </div>
                ) : null}
              </div>
              {record.snappImage && (
                <Image
                  src={record.snappImage}
                  alt={title}
                  style={{ width: '100%' }}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={4} direction={'column'}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h5"
                    className={classes.headline}
                    gutterBottom
                  >
                    Assign Plant
                  </Typography>
                  <Divider style={{ marginBottom: '20px' }} />
                  <Loader loaded={!assigningPlant}>
                    <div>
                      <Async
                        defaultInputValue={selectedPlant.label}
                        defaultValue={selectedPlant.value}
                        loadOptions={this.getPlants}
                        onChange={this.handlePlantChange}
                        style={{ marginBottom: '10px' }}
                        styles={reactSelectStyles}
                      />
                      {selectedPlant.label && (
                        <div className={classes.headers}>
                          <Typography variant="h5" gutterBottom>
                            Plant name:
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            {selectedPlant.label}
                          </Typography>
                        </div>
                      )}
                      {selectedPlant.label && (
                        <div className={classes.headers}>
                          <Typography variant="h5" gutterBottom>
                            Plant Description:
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            {selectedPlant.plantDescription}
                          </Typography>
                        </div>
                      )}
                      <TextField
                        id="snappComment"
                        label="Comment"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        multiline
                        placeholder="Comment"
                        fullWidth
                        onChange={this.handlePlantCommentChange}
                        rows="4"
                        margin="normal"
                      />
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          key="cancelAssignPlant"
                          variant="contained"
                          className={classes.button}
                          onClick={() => this.props.history.push('/snapps')}
                        >
                          Cancel
                        </Button>
                        <Button
                          key="assignPlant"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => this.assignPlant()}
                        >
                          Assign
                        </Button>
                      </div>
                    </div>
                  </Loader>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h5"
                    className={classes.headline}
                    gutterBottom
                  >
                    Assign Expert
                  </Typography>
                  <Divider style={{ marginBottom: '20px' }} />
                  <Loader loaded={!assigningExpert}>
                    <div>
                      <Select
                        id="user-select"
                        name="selected-user"
                        options={users}
                        defaultValue={selectedUser.value}
                        defaultInputValue={selectedUser.label}
                        onChange={this.handleUserChange}
                        styles={reactSelectStyles}
                      />
                      {selectedUser.label && (
                        <div className={classes.headers}>
                          <Typography variant="h5" gutterBottom>
                            Expert name:
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            {selectedUser.label}
                          </Typography>
                        </div>
                      )}
                      <TextField
                        id="expertComment"
                        label="Comment"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={this.handleExpertCommentChange}
                        multiline
                        placeholder="Comment"
                        fullWidth
                        rows="4"
                        margin="normal"
                      />

                      <div style={{ textAlign: 'center' }}>
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={() => this.props.history.push('/snapps')}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => this.assignExpert()}
                        >
                          Reassign
                        </Button>
                      </div>
                    </div>
                  </Loader>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = ({ snapp }) => ({
  ...snapp,
  initialValues: snapp.record,
})

const mapDispatchToProps = { ...snappActions }

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  headline: {
    color: '#25A652',
  },
  button: {
    margin: theme.spacing(1),
  },
  headers: {
    marginTop: theme.spacing(1),
  },
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SnappForm)
)
