import {
  COUNT_PLANT_FAILED,
  COUNT_PLANT_SUCCEED,
  COUNT_PLANT_PROCESSING,
  FETCH_PLANT_FAILED,
  FETCH_PLANT_SUCCEED,
  FETCH_PLANT_PROCESSING,
  GET_PLANT_FAILED,
  GET_PLANT_SUCCEED,
  GET_PLANT_PROCESSING,
  FETCH_PLANT_GENUS_FAILED,
  FETCH_PLANT_GENUS_SUCCEED,
  FETCH_PLANT_GENUS_PROCESSING,
  FETCH_PLANT_NAME_FAILED,
  FETCH_PLANT_NAME_SUCCEED,
  FETCH_PLANT_NAME_PROCESSING,
  SAVE_PLANT_FAILED,
  SAVE_PLANT_SUCCEED,
  SAVE_PLANT_PROCESSING,
  DELETE_PLANT_FAILED,
  DELETE_PLANT_SUCCEED,
  DELETE_PLANT_PROCESSING,
  SAVE_SNAPP_PHOTO_FAILED,
  SAVE_SNAPP_PHOTO_SUCCEED,
  SAVE_SNAPP_PHOTO_PROCESSING,
  MERGE_PLANTS_FAILED,
  MERGE_PLANTS_SUCCEED,
  MERGE_PLANTS_PROCESSING,
} from '../../constants'
import Parse from '../parse'
import { client } from '../algolia'

const plantIndex = client.initIndex('plant')
const Plant = Parse.Object.extend('Plant')
const useMasterKey = true

function getPlantQuery(searchText, genusQuery) {
  let query = new Parse.Query(Plant)
  searchText = searchText || ''
  if (searchText !== '') {
    const latinNameQuery = new Parse.Query(Plant)
    latinNameQuery.matches('latinName', searchText.trim(), 'i')
    const commonNameQuery = new Parse.Query(Plant)
    commonNameQuery.matches('commonName', searchText.trim(), 'i')
    const descriptionQuery = new Parse.Query(Plant)
    descriptionQuery.matches('plantDescription', searchText.trim(), 'i')
    query = Parse.Query.or(latinNameQuery, commonNameQuery, descriptionQuery)
  }
  if (genusQuery) {
    if (genusQuery === 'none') {
      query.doesNotExist('genus')
    } else {
      query.equalTo('genus', genusQuery)
    }
  }
  return query
}

const fetch = (
  page = 0,
  rowsPerPage = 10,
  searchText = '',
  genusQuery = ''
) => {
  return async dispatch => {
    dispatch({
      type: FETCH_PLANT_PROCESSING,
    })
    const query = getPlantQuery(searchText, genusQuery)
    try {
      const plants = await query
        .descending('updatedAt')
        .limit(rowsPerPage)
        .skip(page * rowsPerPage)
        .find()
      let list = plants.map(plant => {
        return {
          [plant.id]: {
            latinName: plant.get('latinName'),
            commonName: plant.get('commonName'),
            plantDescription: plant.get('plantDescription')
              ? plant.get('plantDescription').slice(0, 100) + '…'
              : '',
            alexaSpokenDescription: plant.get('alexaSpokenDescription'),
            AlexaSearch: plant.get('AlexaSearch'),
            heightAndWidth: plant.get('heightAndWidth'),
            bloomSeason: plant.get('bloomSeason'),
            lightRecommendation: plant.get('lightRecommendation'),
            wateringRecommendation: plant.get('wateringRecommendation'),
            pruningNeeds: plant.get('pruningNeeds'),
            hardiness: plant.get('hardiness'),
            usdaUkPlantingZones: plant.get('usdaUkPlantingZones'),
            timeOfYearToPlant: plant.get('timeOfYearToPlant'),
            soilTypeRecommendation: plant.get('soilTypeRecommendation'),
            buyURL: plant.get('buyURL'),
          },
        }
      })
      return dispatch({
        type: FETCH_PLANT_SUCCEED,
        payload: { list },
      })
    } catch (error) {
      dispatch({
        type: FETCH_PLANT_FAILED,
        payload: {
          error: `Error: ${error.code} ${error.message}`,
        },
      })
    }
  }
}

const get = id => {
  return async dispatch => {
    dispatch({
      type: GET_PLANT_PROCESSING,
    })
    let query = new Parse.Query(Plant)
    try {
      const plant = await query.include('genus').get(id)
      // Get snapp photos
      let querySnapps = new Parse.Query(Parse.Object.extend('Snapp'))
      const snapps = await querySnapps
        .equalTo('expertIdentifiedPlant', plant)
        .limit(10000)
        .find({
          useMasterKey,
        })
      var photos = []
      for (var i = 0; i < snapps.length; i++) {
        let snapp = snapps[i]
        if (snapp.get('thumbImage')) {
          photos.push({
            src: snapp.get('thumbImage').url(),
            alt: snapp.id,
            width: 1,
            height: 1,
            selected: false,
          })
        }
      }
      // Get plant photo
      let plantPhotoQuery = new Parse.Query(Parse.Object.extend('PlantPhoto'))
      const plantPhotos = await plantPhotoQuery
        .equalTo('plantId', plant.id)
        .find()
      const genus = plant.get('genus')
        ? {
            value: plant.get('genus').id,
            label: plant.get('genus').get('genusName'),
          }
        : {}
      return dispatch({
        type: GET_PLANT_SUCCEED,
        payload: {
          record: {
            object_id: plant.id,
            latinName: plant.get('latinName'),
            plantDescription: plant.get('plantDescription'),
            genus,
            commonName: plant.get('commonName'),
            alexaSpokenDescription: plant.get('alexaSpokenDescription'),
            AlexaSearch: plant.get('AlexaSearch'),
            heightAndWidth: plant.get('heightAndWidth'),
            bloomSeason: plant.get('bloomSeason'),
            lightRecommendation: plant.get('lightRecommendation'),
            wateringRecommendation: plant.get('wateringRecommendation'),
            pruningNeeds: plant.get('pruningNeeds'),
            hardiness: plant.get('hardiness'),
            usdaUkPlantingZones: plant.get('usdaUkPlantingZones'),
            timeOfYearToPlant: plant.get('timeOfYearToPlant'),
            soilTypeRecommendation: plant.get('soilTypeRecommendation'),
            buyURL: plant.get('buyURL'),
            visible: plant.get('visible'),
          },
          photos: photos,
          plantPhotos: plantPhotos.map(plantPhoto => ({
            thumbImage: plantPhoto.get('thumbImage')
              ? plantPhoto.get('thumbImage').url()
              : '',
          })),
        },
      })
    } catch (error) {
      dispatch({
        type: GET_PLANT_FAILED,
        payload: {
          error: `Error: ${error.code} ${error.message}`,
        },
      })
    }
  }
}
export const plant = {
  count(searchText, genusQuery = '') {
    return async dispatch => {
      dispatch({
        type: COUNT_PLANT_PROCESSING,
      })
      const query = getPlantQuery(searchText, genusQuery)
      try {
        const total = await query.count({ useMasterKey })
        return dispatch({
          type: COUNT_PLANT_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        return dispatch({
          type: COUNT_PLANT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  async select(searchText) {
    searchText = searchText || ''
    const results = await plantIndex.search({
      query: searchText,
      hitsPerPage: 10,
      page: 0,
    })
    return results.hits.map(hit => {
      let label = hit.latinName ? hit.latinName : ''
      label += hit.latinName && hit.commonName ? ' ' : ''
      label += hit.commonName ? hit.commonName : ''
      return {
        value: hit.objectID,
        label,
        plantDescription: hit.plantDescription || '',
      }
    })
  },
  fetch,
  get,
  getGenus(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_PLANT_GENUS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Genus'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('plantName', searchText);
        query.matches('genusName', searchText.trim(), 'i')
      }
      try {
        const results = await query.limit(10000).find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('genusName'),
          })
        }
        dispatch({
          type: FETCH_PLANT_GENUS_SUCCEED,
          payload: {
            genus: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_PLANT_GENUS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getNamePlants() {
    return async dispatch => {
      dispatch({
        type: FETCH_PLANT_NAME_PROCESSING,
      })
      let query = new Parse.Query(Plant)
      let results = []
      try {
        results = await query.limit(10000).find()
      } catch (error) {
        return dispatch({
          type: FETCH_PLANT_NAME_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        list.push({
          value: object.id,
          label: object.get('commonName') + ' ' + object.get('latinName'),
        })
      }
      return dispatch({
        type: FETCH_PLANT_NAME_SUCCEED,
        payload: {
          namePlants: list,
        },
      })
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_PLANT_PROCESSING,
      })

      let plant = {}
      if (
        values.object_id !== undefined &&
        values.object_id !== null &&
        values.object_id !== ''
      ) {
        const plantQuery = new Parse.Query(Plant)
        try {
          plant = await plantQuery.get(values.object_id)
        } catch (err) {
          return dispatch({
            type: SAVE_PLANT_FAILED,
            payload: {
              error: `Error: ${err.code} ${err.message}`,
            },
          })
        }
        plant.id = values.object_id
      } else {
        plant = new Plant()
      }
      let genus = null
      if (values.genus) {
        const Genus = Parse.Object.extend('Genus')
        const genusQuery = new Parse.Query(Genus)
        genus = await genusQuery.get(values.genus.value)
      }

      plant.set({
        latinName: values.latinName,
        commonName: values.commonName,
        genus,
        plantDescription: values.plantDescription,
        alexaSpokenDescription: values.alexaSpokenDescription,
        AlexaSearch: values.AlexaSearch,
        lightRecommendation: values.lightRecommendation,
        wateringRecommendation: values.wateringRecommendation,
        pruningNeeds: values.pruningNeeds,
        hardiness: values.hardiness,
        usdaUkPlantingZones: values.usdaUkPlantingZones,
        timeOfYearToPlant: values.timeOfYearToPlant,
        soilTypeRecommendation: values.soilTypeRecommendation,
        buyURL: values.buyURL,
        visible: values.visible,
      })
      if (values['plantImage'] && typeof values['plantImage'] === 'object') {
        try {
          let fileName = values['plantImage'].name
          let parseFile = new Parse.File(fileName, values['plantImage'])
          await parseFile.save()
          // The file has been saved to Parse.
          let plantPhotoModel = new Parse.Object.extend('PlantPhoto')
          let plantPhoto = new plantPhotoModel()
          await plantPhoto.save({
            plantId: plant.id,
            plantImage: parseFile,
          })
        } catch (err) {
          return dispatch({
            type: SAVE_PLANT_FAILED,
            payload: {
              error: `Error: ${err.code} ${err.message}`,
            },
          })
        }
      } else {
        try {
          await plant.save(null)
          return dispatch({
            type: SAVE_PLANT_SUCCEED,
          })
        } catch (err) {
          return dispatch({
            type: SAVE_PLANT_FAILED,
            payload: {
              error: `Error: ${err.code} ${err.message}`,
            },
          })
        }
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_PLANT_PROCESSING,
      })
      let query = new Parse.Query(Plant)
      try {
        const plants = await query.containedIn('objectId', ids).find()
        await Parse.Object.destroyAll(plants)
        dispatch({
          type: DELETE_PLANT_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: DELETE_PLANT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  saveSnappPhoto(plantId, snappId) {
    return async dispatch => {
      dispatch({
        type: SAVE_SNAPP_PHOTO_PROCESSING,
      })
      let plantPhotoQuery = new Parse.Query(Parse.Object.extend('PlantPhoto'))
      let snappQuery = new Parse.Query(Parse.Object.extend('Snapp'))
      plantPhotoQuery.equalTo('plantId', plantId)
      snappQuery.equalTo('objectId', snappId)
      try {
        const snapp = await snappQuery.first()
        const oldPhotos = await plantPhotoQuery.find()
        await Parse.Object.destroyAll(oldPhotos)
        let plantPhoto = new Parse.Object('PlantPhoto')
        await plantPhoto.save({
          plantId: plantId,
          plantImage: snapp.get('snappImage'),
        })
        dispatch({
          type: SAVE_SNAPP_PHOTO_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: SAVE_SNAPP_PHOTO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  mergePlants(primary, selected) {
    return async dispatch => {
      dispatch({
        type: MERGE_PLANTS_PROCESSING,
      })
      Parse.Cloud.run('mergePlants', {
        masterPlant: primary,
        plantIdsToDelete: selected,
      })
      try {
        dispatch({
          type: MERGE_PLANTS_SUCCEED,
        })
        fetch()
      } catch (error) {
        dispatch({
          type: MERGE_PLANTS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
}
