import reportAPI from 'api/modules/report.api'
import { HIDE_ALERT } from '../constants'
export const report = {
  numberIdDoneByExpert(onlyLastMonth) {
    return dispatch => {
      dispatch(reportAPI.numberIdDoneByExpert(onlyLastMonth))
    }
  },
  getRetailerGroupsReport({
    retailerGroupId,
    fromDate,
    toDate,
    unit,
    outputFormat,
  }) {
    return dispatch => {
      dispatch(
        reportAPI.getRetailerGroupsReport({
          retailerGroupId,
          fromDate,
          toDate,
          unit,
          outputFormat,
        })
      )
    }
  },
  getChatsByExpert({ fromDate, toDate }) {
    return dispatch => {
      dispatch(
        reportAPI.getChatsByExpert({
          fromDate,
          toDate,
        })
      )
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_ALERT,
      })
    }
  },
}
