import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from "react-router-dom"
import { withStyles } from '@material-ui/core'
import { Header, Footer, Sidebar } from "../components"
import appRoutes from "../routes/app"
import appStyle from "../variables/styles/appStyle.jsx"
import logo from "../assets/img/logo_white.svg"
import DashboardPage from "../components/Home"
import LoginPage from "../components/Login"
import GenusCareInfo from "../components/GenusCareInfo/GenusCareInfoList"
import GenusCareInfoForm from "../components/GenusCareInfo/GenusCareInfoForm"
import CategoryCareInfo from "../components/CategoryCareInfo/CategoryCareInfoList"
import CategoryCareInfoForm from "../components/CategoryCareInfo/CategoryCareInfoForm"

const RouteWithSubRoutes = route => (  
  <div>
      <Route exact path={route.path} component={route.component} />
      {route.routes.map(sub => {
        return <Route exact path={sub.path} component={sub.component} key={sub.action} />
      })}
  </div>
)

const switchRoutes = (
  <Switch>
    <Route path="/login" component={LoginPage} key="login"/>
    <Route path="/dashboard" component={DashboardPage} key="dashboard"/>
    <Route exact path="/genusCareInfo" component={GenusCareInfo} key="genuscareinfo"/>
    <Route exact path="/genusCareInfo/add" component={GenusCareInfoForm} key="genuscareinfo_add" />
    <Route exact path="/genusCareInfo/edit/:id" component={GenusCareInfoForm} key="genuscareinfo_edit" />
    <Route exact path="/genusCareInfo/delete/:id" component={GenusCareInfo} key="genuscareinfo_delete" />
    <Route exact path="/categoryCareInfo" component={CategoryCareInfo} key="categorycareinfo"/>
    <Route exact path="/categoryCareInfo/add" component={CategoryCareInfoForm} key="categorycareinfo_add" />
    <Route exact path="/categoryCareInfo/edit/:id" component={CategoryCareInfoForm} key="categorycareinfo_edit" />
    <Route exact path="/categoryCareInfo/delete/:id" component={CategoryCareInfo} key="categorycareinfo_delete" />    
    {appRoutes.map((prop, key) => {
      if (prop.redirect)        
        return <Redirect from={prop.path} to={prop.to} key={key} />      
      if(prop.routes !== undefined){        
        return <RouteWithSubRoutes key={key} {...prop} />
      }
      else{
        return <Route path={prop.path} component={prop.component} key={key} />
      }      
    })}
  </Switch>
)
class App extends React.Component {
  state = {
    mobileOpen: false,
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }
  componentDidMount() {    
    this.correctRoute()

  }
  componentDidUpdate() {
    this.correctRoute()
  }
  correctRoute(){
    if(this.props.isLoggedIn){
      if(this.props.location.pathname === "/login"){
        this.props.history.push('/dashboard')      
      }
      this.refs.mainPanel.scrollTop = 0
    }else{
      if(this.props.location.pathname !== "/login"){
        this.props.history.push('/login')
      }      
    }
  }
  render() {
    const { classes, ...rest } = this.props
    return (
      <main>
      { this.props.isLoggedIn ? (
        <div>
          <Sidebar
            routes={appRoutes}
            logoText={"Creative Tim"}
            logo={logo}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color="blue"
            dark
            {...rest}
          />
          <div className={classes.mainPanel} ref="mainPanel">
            <Header
              routes={appRoutes}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />          
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>          
            <Footer />
          </div>
        </div>
      ) : (
        <div className={classes.map}>{switchRoutes}</div>
      )}
      </main>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
}
function mapStateToProps(state) {
  return {
    userId: state.auth.userId,
    isLoggedIn: state.auth.isLoggedIn
  }
}


export default withStyles(appStyle)(connect(mapStateToProps)(App))
