import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Button, CardActions } from '@material-ui/core'

import RegularCard from '../Cards/RegularCard.js'
import userActions from '../../actions/user.actions'
import CustomTextField from 'components/Input/CustomTextField.js'
import formPropTypes from 'constants/formPropTypes.js'
import CustomSelect from 'components/Input/CustomSelect.js'
import CustomSnackbar from 'components/CustomSnackbar/index.js'

export const fields = [
  'object_id',
  'email',
  'password',
  'confirmPassword',
  'region',
]

const onSubmit = async (values, dispatch, props) => {
  values.object_id = props.initialValues.object_id
  await props.save(values)
}

const validate = values => {
  const errors = {}
  if (values.password) {
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords must match'
    }
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  return errors
}

class UsersForm extends Component {
  static propTypes = {
    ...formPropTypes,
  }

  handleChangeImage() {}
  componentDidMount() {
    this.props.getRegions()
    if (this.props.match.params.id) {
      this.props.get(this.props.match.params.id)
    }
  }
  hideAlert() {
    this.props.hideAlert()
    this.props.record
      ? window.location.reload()
      : this.props.history.push('/user')
  }
  render() {
    const {
      record,
      regions,
      handleSubmit,
      submitting,
      submitSucceeded,
      fetching,
      pristine,
      history,
      error,
    } = this.props
    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') + record.email
    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={Boolean(error)}
          message={error}
          handleClose={() => this.handleCloseError()}
        />
        <CustomSnackbar
          variant="info"
          open={Boolean(submitting)}
          message="Saving"
        />
        <CustomSnackbar
          variant="success"
          open={Boolean(submitSucceeded)}
          message="Saved"
        />
        <RegularCard
          cardTitle={title}
          content={
            <form onSubmit={handleSubmit}>
              <div>
                <Field name="email" component={CustomTextField} label="Email" />
              </div>
              <div>
                <Field
                  name="password"
                  type="password"
                  component={CustomTextField}
                  label="Password"
                />
              </div>
              <div>
                <Field
                  name="confirmPassword"
                  type="password"
                  component={CustomTextField}
                  label="Confirm Password"
                />
              </div>
              <div>
                <label>Region</label>
                <Field
                  name="region"
                  label="Region"
                  options={regions}
                  placeholder="Select regions"
                  component={CustomSelect}
                />
              </div>
            </form>
          }
          footer={
            <CardActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={fetching || pristine}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.push('/user')}
              >
                Back to list
              </Button>
            </CardActions>
          }
        />
      </div>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  record: user.record,
  regions: user.regions,
  fetching: user.fetching,
  fetched: user.fetched,
  saving: user.saving,
  saved: user.saved,
  errorMessage: user.errorMessage,
  fields,
  initialValues: user.record,
})

const mapDispatchToProps = { ...userActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'user',
    validate,
    onSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(UsersForm)
)
