import {
  COUNT_CATEGORY_FAILED,
  COUNT_CATEGORY_SUCCEED,
  COUNT_CATEGORY_PROCESSING,
  FETCH_CATEGORY_FAILED,
  FETCH_CATEGORY_SUCCEED,
  FETCH_CATEGORY_PROCESSING,
  GET_CATEGORY_FAILED,
  GET_CATEGORY_SUCCEED,
  GET_CATEGORY_PROCESSING,
  FETCH_CATEGORY_PRODUCTS_FAILED,
  FETCH_CATEGORY_PRODUCTS_SUCCEED,
  FETCH_CATEGORY_PRODUCTS_PROCESSING,
  SAVE_CATEGORY_FAILED,
  SAVE_CATEGORY_SUCCEED,
  SAVE_CATEGORY_PROCESSING,
  DELETE_CATEGORY_FAILED,
  DELETE_CATEGORY_SUCCEED,
  DELETE_CATEGORY_PROCESSING,
} from '../../constants'
import Parse from '../parse'

export const category = {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_CATEGORY_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('GenusCategory'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('name', searchText.trim(), 'i')
      }
      try {
        const total = await query.count({ useMasterKey: true })
        dispatch({
          type: COUNT_CATEGORY_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        dispatch({
          type: COUNT_CATEGORY_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  list(page, rowsPerPage, searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_CATEGORY_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('GenusCategory'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('name', searchText.trim(), 'i')
      }
      query.limit(rowsPerPage)
      query.skip(page * rowsPerPage)
      query.descending('createdAt')
      try {
        const results = await query.find()
        var list = []
        list = results.map(r => {
          return {
            [r.id]: {
              categoryName: r.get('name'),
              categoryDescription: r.get('categoryDescription'),
            },
          }
        })
        dispatch({
          type: FETCH_CATEGORY_SUCCEED,
          payload: {
            list: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_CATEGORY_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_CATEGORY_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('GenusCategory'))
      query.include('recommendedProducts')
      try {
        const category = await query.get(id)
        const record = {
          object_id: category.id,
          name: category.get('name'),
          categoryDescription: category.get('categoryDescription'),
          recommendedProducts: category
            .get('recommendedProducts')
            .map(product => ({
              value: product.id,
              label: product.get('productName'),
            })),
        }
        dispatch({
          type: GET_CATEGORY_SUCCEED,
          payload: {
            record,
          },
        })
      } catch (error) {
        dispatch({
          type: GET_CATEGORY_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getProducts(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_CATEGORY_PRODUCTS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Products'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('productName', searchText);
        query.matches('productName', searchText.trim(), 'i')
      }
      query.limit(10000)

      try {
        const results = await query.find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('productName'),
          })
        }
        dispatch({
          type: FETCH_CATEGORY_PRODUCTS_SUCCEED,
          payload: {
            products: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_CATEGORY_PRODUCTS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_CATEGORY_PROCESSING,
      })
      let categoryModel = new Parse.Object.extend('GenusCategory')
      let category = new categoryModel()
      if (
        values.object_id !== undefined &&
        values.object_id !== null &&
        values.object_id !== ''
      ) {
        category.id = values.object_id
      }
      for (var k in values) {
        if (k !== 'object_id') {
          if (k === 'recommendedProducts' && values[k]) {
            var products = []
            var list = values[k]
            for (var i = 0; i < list.length; i++) {
              let productModel = new Parse.Object.extend('Products')
              let product = new productModel()
              product.id = list[i]
              products.push(product)
            }
            category.set(k, products)
          } else {
            category.set(k, values[k])
          }
        }
      }
      try {
        await category.save(null)
        dispatch({
          type: SAVE_CATEGORY_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: SAVE_CATEGORY_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_CATEGORY_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('GenusCategory'))
      try {
        const categories = await query.containedIn('objectId', ids).find()
        await Parse.Object.destroyAll(categories)
        dispatch({
          type: DELETE_CATEGORY_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: DELETE_CATEGORY_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
}
