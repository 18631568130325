import {
  COUNT_RETAILERSGROUP_FAILED,
  COUNT_RETAILERSGROUP_SUCCEED,
  COUNT_RETAILERSGROUP_PROCESSING,
  FETCH_RETAILERSGROUP_FAILED,
  FETCH_RETAILERSGROUP_SUCCEED,
  FETCH_RETAILERSGROUP_PROCESSING,
  GET_RETAILERSGROUP_FAILED,
  GET_RETAILERSGROUP_SUCCEED,
  GET_RETAILERSGROUP_PROCESSING,
  FETCH_RETAILERSGROUP_REGIONS_FAILED,
  FETCH_RETAILERSGROUP_REGIONS_SUCCEED,
  FETCH_RETAILERSGROUP_REGIONS_PROCESSING,
  FETCH_RETAILERSGROUP_PRODUCTS_FAILED,
  FETCH_RETAILERSGROUP_PRODUCTS_SUCCEED,
  FETCH_RETAILERSGROUP_PRODUCTS_PROCESSING,
  SAVE_RETAILERSGROUP_FAILED,
  SAVE_RETAILERSGROUP_SUCCEED,
  SAVE_RETAILERSGROUP_PROCESSING,
  SAVE_RETAILERSGROUP_PRODUCTS_FAILED,
  SAVE_RETAILERSGROUP_PRODUCTS_SUCCEED,
  SAVE_RETAILERSGROUP_PRODUCTS_PROCESSING,
  DELETE_RETAILERSGROUP_FAILED,
  DELETE_RETAILERSGROUP_SUCCEED,
  DELETE_RETAILERSGROUP_PROCESSING,
  HIDE_ALERT,
} from '../constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  savingProducts: false,
  savedProducts: false,
  deleting: false,
  deleted: false,
  errorMessage: '',
  list: [],
  total: 0,
  regions: [],
  categories: [],
  products: [],
  retailersGroupProducts: [],
  record: {
    name: '',
    logo: '',
    selectedProducts: [],
    regions: [],
    isPartner: false,
    isDefault: false,
  },
}

const retailersGroup = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RETAILERSGROUP_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
        list: [],
      }
    case FETCH_RETAILERSGROUP_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case FETCH_RETAILERSGROUP_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        errorMessage: '',
        deleted: false,
      }
    case GET_RETAILERSGROUP_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
        record: initialState.record,
      }
    case GET_RETAILERSGROUP_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case GET_RETAILERSGROUP_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        retailersGroupProducts: action.payload.retailersGroupProducts,
        errorMessage: '',
      }
    case COUNT_RETAILERSGROUP_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_RETAILERSGROUP_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_RETAILERSGROUP_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_RETAILERSGROUP_REGIONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_RETAILERSGROUP_REGIONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_RETAILERSGROUP_REGIONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        regions: action.payload.regions,
      }
    case FETCH_RETAILERSGROUP_PRODUCTS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_RETAILERSGROUP_PRODUCTS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_RETAILERSGROUP_PRODUCTS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        products: action.payload.products,
        categories: action.payload.categories,
      }
    case SAVE_RETAILERSGROUP_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
        errorMessage: action.payload.error,
      }
    case SAVE_RETAILERSGROUP_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_RETAILERSGROUP_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case SAVE_RETAILERSGROUP_PRODUCTS_FAILED:
      return {
        ...state,
        savingProducts: false,
        savedProducts: false,
        errorMessage: action.payload.error,
      }
    case SAVE_RETAILERSGROUP_PRODUCTS_PROCESSING:
      return {
        ...state,
        savingProducts: true,
        savedProducts: false,
      }
    case SAVE_RETAILERSGROUP_PRODUCTS_SUCCEED:
      return {
        ...state,
        savingProducts: false,
        savedProducts: true,
      }
    case DELETE_RETAILERSGROUP_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_RETAILERSGROUP_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_RETAILERSGROUP_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        savedProducts: false,
        errorMessage: '',
      }
    default:
      return state
  }
}

export default retailersGroup
